import React, { Component } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';

class QuestionnairePersonal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json1: props.personal
        };
    }

    onChangeJson = (e) => {
        console.log(e);
        this.setState({ json1: e.jsObject });
        
    }
    jsonSave = () =>{
        this.props.update(this.state.json1);
    }
    render() {
        return (
            <div>
                Személyes adatok
                <JSONInput
                    id='a_unique_id'
                    placeholder={this.props.personal}
                    // colors={darktheme}
                    locale={locale}
                    onChange={this.onChangeJson}
                    height='550px'
                />
                 <Button
                        variant='contained'
                        color='primary'
                        startIcon={<CreateIcon />}
                        onClick={this.jsonSave}
                    >
                        Json ment
                    </Button>
            </div>
        );
    }
}

export default QuestionnairePersonal;
import React from 'react';
import { ReferenceLine, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Text } from 'recharts';
import { subscaleMaxValue, chartColors, titles, leaders, everybody } from './FilledStatsConsts';

const TszkBarChart = (params) => {
    const { title, data, customizedAxisTick, dataKey } = params;
    const barColors = [
        '#82ca9d',
        '#4285f4',
        '#ea4335',
        '#fbbc04',
        '#34a853',
        '#ff6d01',
        '#46bdc6',
        '#7baaf7',
        '#f07b72',
        '#fcd04f',
        '#71c287',
        '#ff994d',
        '#7ed1d7',
        '#6038de',
        '#b1b5dc']

    const renderCustomizedLabel2 = (props) => {
        const { x, y, width, height, value } = props;
        return (
            <g>
                <text x={x + width / 2} y={y + height / 2} fill="#fff" textAnchor="middle" dominantBaseline="middle">
                    {props.value != '0' ? `${parseInt(value)}` : ''}
                </text>
            </g>
        );
    }
    return (
        <>
            <h2>{title}</h2>
            <ResponsiveContainer width="80%" height={300}>

                <BarChart
                    data={data}
                    // layout="vertical"
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="name"
                        interval={0}
                        tick={customizedAxisTick}
                    />
                    {/* <XAxis dataKey="name" interval={0} rotate={30}/> */}
                    {/* <XAxis tick={<CustomizedTick />} /> */}
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={dataKey ?? "Kitöltők száma"} fill={chartColors[0]}>
                        <LabelList dataKey={dataKey ?? "Kitöltők száma"} dataValues={data} content={renderCustomizedLabel2} />
                        {
                            // data.map((entry, index) => (
                            //     <Cell key={`cell-${index}`} fill={barColors[index]} />
                            // ))
                        }
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};

export default TszkBarChart;
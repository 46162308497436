import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        bgcolor: 'primary.main',
        marginBottom: theme.spacing(4),
    },
    question: {
        height: '40vh',
        textAlign: "center",
        alignContent: "center",
        backgroundColor: 'primary.main',
    },
    questionPanel: {
        textAlign: "center",
        alignContent: "center",
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    button: {
        margin: theme.spacing(1),
    },
    error: {
        color: '#eaa',
    },
    containedGreen: {
        color: '#fff',
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
            '@media (hover: none)': {
                backgroundColor: green[500],
            },
        },
    },
    containedRed: {
        color: '#fff',
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
            '@media (hover: none)': {
                backgroundColor: red[500],
            },
        },
    },
}));

const BaseStatistics = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { actualInstitution, authStatus, filledquestionnaires, questionnaires, users, updateEvaluations } = props;
    const { isEmpty, isLoaded } = authStatus;
    console.log(props);
    useFirestoreConnect([
        {
            collection: 'questionnaires',
            doc: '2XUJ9t7zegsFlbDvv4Er'
        },
        {
            collection: 'filledquestionnaires',
            where: [
                ['institutions', 'array-contains', actualInstitution],
                ['status', '==', 'evaluated'],
            ],
        },
        {
            collection: 'users',
        },
    ]);

    let data = [];
    if (questionnaires && filledquestionnaires) {
        Object.keys(questionnaires[0].personal[1].values).forEach(q => {
            console.log(questionnaires[0].personal[1].values[q].label);
            console.log(filledquestionnaires.filter(x => x.personal[2] == questionnaires[0].personal[1].values[q].value));
            data.push({
                name: questionnaires[0].personal[1].values[q].label,
                év: filledquestionnaires.filter(x => x.personal[2] == questionnaires[0].personal[1].values[q].value).length,
            });
        }
        );
    };
    let data2 = [];
    if (questionnaires && filledquestionnaires) {
        Object.keys(questionnaires[0].personal[2].values).forEach(q => {
            console.log(questionnaires[0].personal[2].values[q].label);
            console.log(filledquestionnaires.filter(x => x.personal[2] == questionnaires[0].personal[1].values[q].value));
            data2.push({
                name: questionnaires[0].personal[2].values[q].label,
                pozíció: filledquestionnaires.filter(x => x.personal[3] == questionnaires[0].personal[2].values[q].value).length,
            });
        }
        );
    };
    dispatch({ type: actions.SET_MENU, payload: 'Statisztika' })
    return (
        <Container maxWidth='sm'>
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                mt='1.5rem'
            >
                <h2>{questionnaires &&
                    questionnaires[0].title}
                </h2>
                <h3>Mióta dolgozik az adott területen</h3>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        <BarChart
                            width={800}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="év" fill="#82ca9d" />
                        </BarChart>
                    )
                    )
                }
                <h3>Betöltött pozíció</h3>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        <BarChart
                            width={800}
                            height={300}
                            data={data2}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="pozíció" fill="#82ca9d" />
                        </BarChart>
                    )
                    )
                }
            </Box>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        questionnaires: state.firestore.ordered.questionnaires,
        filledquestionnaires: state.firestore.ordered.filledquestionnaires,
        users: state.firestore.ordered.users,
        authStatus: state.firebase.auth,
        actualInstitution: state.questionnaire.institution,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // deleteQuestionnaire: (id) => dispatch(deleteQuestionnaire(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseStatistics);
import React from "react"
import ContentLoader from "react-content-loader"
import { BulletList } from 'react-content-loader'
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const FSPTableLoader = (props) => (
  <>
    <TableRow>
     
    <BulletList />
    </TableRow>
  </>
)

export default FSPTableLoader
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const SignedOutLinks = () => {
  return (
    <>
      <Button color='inherit' component={Link} to='/signup'>
        Sign Up
      </Button>
      <Button color='inherit' component={Link} to='/signin'>
        Kérdőiv Admin
      </Button>
    </>
  );
};

export default SignedOutLinks;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { updateQuestionnaire } from '../../../store/actions/questionnaireActions';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import QuestionnaireItems from './QuestionnaireItems';
import { useFirestoreConnect } from 'react-redux-firebase';
import QuestionnairePersonal from './QuestionnairePersonal';
import QuestionnaireScales from './QuestionnaireScales';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
  },
  marginButton: {
    marginTop: theme.spacing(4),
  },
  rounded: {
    color: '#fff',
    backgroundColor: green[500],
    marginTop: theme.spacing(4),
  },
  select: {
    marginTop: theme.spacing(2),
    fontSize: '16px',
  },
  circularProgressWhite: {
    color: '#fff',
  },
}));

const UpdateQuestionnaire = ({
  userStatus,
  updateStatus,
  updateQuestionnaire,
  questionnairesInfo,
  match,
}) => {
  const { isEmpty, isLoaded } = userStatus;
  const { loading, error } = updateStatus;
  const { title, description, version, gdpr, personal, scales, questionnaire, state, updatedAt } = questionnairesInfo;

  const classes = useStyles();
  const history = useHistory();

  const [questionnaires, setQuestionnaire] = useState({
    title,
    description,
    version,
    questionnaire,
    gdpr,
    personal,
    scales,
    state,
    updatedAt
  });

  const handleChange = (e) => {
    console.log(e.target.id, e.target.value);
    setQuestionnaire({ ...questionnaires, [e.target.id]: e.target.value });
  };

  const handleStateChange = (e) => {
    setQuestionnaire({ ...questionnaires, state: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateQuestionnaire(questionnaires, match.params.id);
    history.push('/');
  };

  const updateItems = (e) => {
    setQuestionnaire({ ...questionnaires, questionnaire: e });
  };

  const updatePersonal = (e) => {
    setQuestionnaire({ ...questionnaires, personal: e });
  };
  const updateScales = (e) => {
    setQuestionnaire({ ...questionnaires, scales: e });
  };

  useFirestoreConnect([
    { collection: 'questionnaires', orderBy: ['createdAt', 'desc'] },
  ]);
  if (isLoaded && !isEmpty) {
    if (questionnairesInfo.updatedAt > questionnaires.updatedAt)
      setQuestionnaire({ ...questionnairesInfo });
    return (
      <Container maxWidth='sm'>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h5' color='inherit' className={classes.margin}>
            Szerkesztés
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            display='flex'
            flexDirection='row'
            flexWrap='wrap'
            alignItems='center'
            justifyContent='space-between'
            mt='1rem'
          >
            <TextField
              fullWidth
              id='title'
              label='Cím'
              value={questionnaires.title}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              multiline
              id='description'
              label='Leírás'
              value={questionnaires.description}
              onChange={handleChange}
              className={classes.margin}
            />
            <TextField
              fullWidth
              multiline
              id='gdpr'
              label='GDPR'
              value={questionnaires.gdpr}
              onChange={handleChange}
              className={classes.margin}
            />
            <TextField
              fullWidth
              multiline
              id='personal'
              label='Személyes adatok'
              value={questionnaires.personal}
              onChange={handleChange}
              className={classes.margin}
            />
            <TextField
              fullWidth
              multiline
              id='version'
              label='Verzió'
              value={questionnaires.version}
              onChange={handleChange}
              className={classes.margin}
            />
            <TextField
              fullWidth
              multiline
              id='questionnaire'
              label='teszt'
              value={questionnaires.questionnaire}
              onChange={handleChange}
              className={classes.margin}
            />
            <FormControl fullWidth className={classes.select}>
              <InputLabel id='state-label'>Státusz</InputLabel>
              <Select
                id='state'
                value={questionnaires.state}
                onChange={handleStateChange}
              >
                <MenuItem value={'Lezárva'}>Lezárva</MenuItem>
                <MenuItem value={'Publikus'}>Publikus</MenuItem>
                <MenuItem value={'Szerkesztés alatt'}>Szerkesztés alatt</MenuItem>
              </Select>
            </FormControl>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.marginButton}
            >
              {loading ? (
                <CircularProgress className={classes.circularProgressWhite} />
              ) : (
                'Módosít'
              )}
            </Button>
            <Button
              variant='contained'
              type="button"
              className={classes.marginButton}
              onClick={() => { history.goBack() }}
            >
              Mégsem
            </Button>
            {error ? (
              <Alert
                variant='filled'
                severity='error'
                className={classes.standardError}
              >
                Error occurred while trying to update the questionnaires item, please try
                again later
              </Alert>
            ) : null}
          </Box>
        </form>
        <div className="questionnairesListMain">
          <div className="header">
            <QuestionnaireItems items={questionnaires.questionnaire} update={updateItems} />
            <QuestionnairePersonal personal={questionnaires.personal} update={updatePersonal} />
            <QuestionnaireScales data={questionnaires.scales} update={updateScales} />
          </div>
        </div>
      </Container>
    );
  } else {
    return <Redirect to='/signin' />;
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    userStatus: state.firebase.auth,
    updateStatus: state.questionnaire.update,
    questionnairesInfo: state.firestore.data.questionnaires
      ? state.firestore.data.questionnaires[ownProps.match.params.id]
      : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuestionnaire: (questionnaires, id) => dispatch(updateQuestionnaire(questionnaires, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateQuestionnaire);

import * as actions from '../actions/actionTypes';

const initState = {
  error: null,
  loading: false,
};

const signInStart = (state) => {
  return { ...state, loading: true };
};

const signInSucess = (state) => {
  return { ...state, error: false, loading: false };
};

const signInFail = (state, payload) => {
  console.log(payload);
  return { ...state, error: true, loading: false };
};

const signUpStart = (state) => {
  return { ...state, loading: true };
};

const signUpSucess = (state) => {
  return { ...state, error: false, loading: false };
};

const signUpFail = (state, payload) => {
  console.log(payload);
  return { ...state, error: true, loading: false };
};

const signOutStart = (state) => {
  return { ...state, loading: true };
};

const signOutSucess = (state) => {
  return { ...state, error: false, loading: false };
};

const signOutFail = (state, payload) => {
  console.log(payload);
  return { ...state, error: true, loading: false };
};

const authReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SIGN_IN_START:
      return signInStart(state);

    case actions.SIGN_IN_SUCESS:
      return signInSucess(state);

    case actions.SIGN_IN_FAIL:
      return signInFail(state, payload);

    case actions.SIGN_UP_START:
      return signUpStart(state);

    case actions.SIGN_UP_SUCESS:
      return signUpSucess(state);

    case actions.SIGN_UP_FAIL:
      return signUpFail(state, payload);

    case actions.SIGN_OUT_START:
      return signOutStart(state);

    case actions.SIGN_OUT_SUCESS:
      return signOutSucess(state);

    case actions.SIGN_OUT_FAIL:
      return signOutFail(state, payload);

    default:
      return state;
  }
};

export default authReducer;

import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import Questionnaire from '../projects/Questionnaire';
import { deleteQuestionnaire } from '../../../store/actions/questionnaireActions';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import useStyles from "./styles";
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';

const Dashboard = ({ questionnaires, authStatus, deleteQuestionnaire }) => {
  const { isEmpty, isLoaded } = authStatus;
  const classes = useStyles();
  const dispatch = useDispatch();

  useFirestoreConnect([
    { collection: 'questionnaires', orderBy: ['createdAt', 'asc'] },
  ]);
  console.log(questionnaires);
  dispatch({ type: actions.SET_MENU, payload: 'Kérdőívek' });
  return (
    <Container className={classes.content}>
      <div>
        <h1>Kérdőívek</h1>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to='/signin' />
          ) : (
            questionnaires &&
            questionnaires.map((questionnaire) => {
              return <Questionnaire questionnaire={questionnaire} deleteQuestionnaire={deleteQuestionnaire} key={questionnaire.id} />;
            })
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    questionnaires: state.firestore.ordered.questionnaires,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteQuestionnaire: (id) => dispatch(deleteQuestionnaire(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

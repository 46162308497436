import React from 'react';
import { ReferenceLine, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Text } from 'recharts';
import { subscaleMaxValue, chartColors, titles, leaders, everybody } from './FilledStatsConsts';

const createTszkIndex = (value) => {
    let v = value;
    let index = 'Nincs tudatában';
    switch (true) {
        case (v < 20):
            index = 'Nincs tudatában';
            break;
        case (v < 40):
            index = 'Tudatában van';
            break;
        case (v < 60):
            index = 'Megérti';
            break;
        case (v < 80):
            index = 'Elhiszi';
            break;
        case (v <= 100):
            index = 'Cselekszik';
            break;
        default:
            index = 'Cselekszik';
    }
    return index;
}

const tszkScaleIndex = (value) => {
    let v = value;
    let index = 'Nincs tudatában';
    switch (true) {
        case (v < 20):
            index = 'Nincs tudatában';
            break;
        case (v < 40):
            index = 'Tudatában van';
            break;
        case (v < 65):
            index = 'Megérti';
            break;
        case (v < 85):
            index = 'Elhiszi';
            break;
        case (v <= 100):
            index = 'Cselekszik';
            break;
        default:
            index = 'Cselekszik';
    }
    return index;
}

const tszkIndex = (value) => {
    let v = value;
    let index = 'Nincs tudatában';
    switch (true) {
        case (v < 18, 75):
            index = 'Nincs tudatában';
            break;
        case (v < 38, 75):
            index = 'Tudatában van';
            break;
        case (v < 60):
            index = 'Megérti';
            break;
        case (v < 80):
            index = 'Elhiszi';
            break;
        case (v <= 100):
            index = 'Cselekszik';
            break;
        default:
            index = 'Cselekszik';
    }
    return index;
}

const TszkIndex = (params) => {
    const { title, data, dataKey, height, yAxisWidth } = params;

    const renderCustomizedLabel2 = (props) => {
        const { x, y, width, height, value } = props;
        return (
            <g>
                <text x={x + width / 2} y={y + 11} fill="#333" textAnchor="middle" dominantBaseline="middle">
                    {props.value != '0' ? `${createTszkIndex(props.value)} (${parseInt(value)}%)` : ''}
                </text>
            </g>
        );
    }

    return (
        <>
            <h3>{title}</h3>
            <ResponsiveContainer width="80%" height={height ?? 300}>
                <BarChart
                    // width={1800}
                    // height={300}
                    data={data}
                    layout="vertical"
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" domain={[0, 100]} />
                    {/* <XAxis dataKey="name" interval={0} rotate={30}/> */}
                    {/* <XAxis tick={<CustomizedTick />} /> */}
                    <YAxis dataKey="name" interval={0} type="category"
                        // tickMargin={30}
                        width={yAxisWidth ?? 150}
                    />
                    <Tooltip />
                    <Legend />
                    <ReferenceLine x="20" stroke="red" label="" />
                    <ReferenceLine x="40" stroke="red" label="" />
                    <ReferenceLine x="60" stroke="red" label="" />
                    <ReferenceLine x="80" stroke="red" label="" />
                    <Bar dataKey={dataKey ?? "TSZK Index"} fill={chartColors[0]} minPointSize={5}>
                        <LabelList dataKey={dataKey ?? "TSZK Index"} dataValues={data} content={renderCustomizedLabel2} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};

export default TszkIndex;
import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { addInstitution, deleteInstitution, updateInstitution } from '../../../store/actions/institutionActions';
import Container from '@material-ui/core/Container';
import useStyles from "../dashboard/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import FSPTable from '../../common/FSPTable/FSPTable';
import firebase from 'firebase/app';
// import { firestore } from 'firebase';

const Institutions = ({ admins, institutions, authStatus, addInstitution, updateInstitution, deleteInstitution }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isEmpty, isLoaded } = authStatus;
    const firestore = firebase.firestore;
    const form = {
        titles: {
            index: 'Intézmények',
            create: 'Új intézmény',
            update: 'Intézmény módosítása',
        },
        keyField: 0,
        indexFields: [0, 1],
        fields: [
            {
                id: 0,
                name: 'om',
                required: true,
                helper: 'OM',
                q2: 'OM',
                label: 'OM',
                type: 2,
            },
            {
                id: 1,
                required: true,
                name: 'name',
                helper: 'Intézmény neve',
                q2: 'Intézmény neve',
                label: 'Intézmény neve',
                type: 2,
            }
        ]
    };
    useFirestoreConnect([
        {
            collection: "institutions",
            storeAs: 'institutions',
            // doc: ['1tKAePwhQW99YAAXarTy'],
            where: [
                // ['uid', 'in', admins && admins[0] && admins[0].institution  ? admins[0].institution : ['1tKAePwhQW99YAAXarTy']],
                [firestore.FieldPath.documentId(), 'in', admins && admins[0] && admins[0].institution  ? admins[0].institution.slice(0,9) : ['xx']],
            ],
        },
        {
            collection: "admins",
            doc: authStatus.uid,
            //    where: [
            //     ['doc', '==', authStatus.uid],
            // ],
            storeAs: 'admins'
        }
    ]);
    const createElement = (newMeasure) => {
        if (typeof newMeasure.id === 'undefined') {
            addInstitution(newMeasure);
        } else {
            updateInstitution(newMeasure, newMeasure.id)
        }
    }

    const deleteElement = (newMeasure) => {
        deleteInstitution(newMeasure);
    }

    dispatch({ type: actions.SET_MENU, payload: 'Intézmények' })

    console.log( admins && admins[0] && admins[0].institution  ? admins[0].institution : ['xxx']);
    return (
        <Container className={classes.content}>
            <div>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        institutions ?
                            <FSPTable
                                model={institutions}
                                classes={classes}
                                createElement={(measure) => createElement(measure)}
                                deleteElement={(measure) => deleteElement(measure)}
                                form={form}
                            /> :
                            <CircularProgress />
                    ))}
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        institutions: state.firestore.ordered.institutions,
        admins: state.firestore.ordered.admins,
        authStatus: state.firebase.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInstitution: (institution) => dispatch(addInstitution(institution)),
        deleteInstitution: (id) => dispatch(deleteInstitution(id)),
        updateInstitution: (institution, id) => dispatch(updateInstitution(institution, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Institutions);

import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Delete, Visibility, Edit } from '@material-ui/icons';

class InstitutionsRow extends Component {
    render() {
        const { element, users, questionnaires } = this.props;
        console.log('element', element.participant);
        console.log('element', questionnaires);
        const a = element && element.participant ? Object.keys(element.participant).length+1 : '1';
        return (
            <>
                <TableRow>
                    <TableCell style={{ maxWidth: '200', padding: '0' }} rowSpan={a}>
                        {users && users[element.id] && users[element.id].email};
                    </TableCell>
                    <TableCell rowSpan={a} >
                        {element.isAdmin ? 'Admin' : 'nem'}
                    </TableCell>
                    </TableRow>
                    {element && element.participant &&
                        Object.keys(element.participant).map((p, index) => (
                            <>
                                <TableRow>
                                    <TableCell>
                                        {questionnaires && questionnaires[p] && questionnaires[p].title}
                                    </TableCell>
                                    <TableCell>
                                    <Delete />
                                    </TableCell>
                                </TableRow>

                            </>
                        ))
                    }
                
            </>
        );
    }
}

export default InstitutionsRow;
import * as actions from '../actions/actionTypes';

/*
 * Separating the state for adding, updating and deleting todos for better usability and future improvements.
 */

const initState = {
    chartData: [],
};

const setChartData = (state, data) => {
    console.log('data.....',data);
    return {
        ...state,
        chartData: data
    }
}

const chartReducer = (state = initState, { type, payload }) => {
    switch (type) {

        case actions.SET_CHART_DATA:
            return setChartData(state, payload);

        default:
            return state;
    }
};

export default chartReducer;

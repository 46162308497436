import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { addAdmins, deleteAdmins, updateAdmins } from '../../../store/actions/adminsActions';
import Container from '@material-ui/core/Container';
import useStyles from "../dashboard/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import FSPTable from '../../common/FSPTable/FSPTable';
import Institutions from '../institutions/Institutions';

const Admins = ({ admins, users, institutions, authStatus, addAdmins, updateAdmins, deleteAdmins }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isEmpty, isLoaded } = authStatus;
    const convertArrayById = (aa) => {
        let a = [];
        aa && aa.forEach(p => {
            a[p.id] = p;
        })

        return a;
    }
    let usersArray = users ? convertArrayById(users) : [];
    let institutionsArray = institutions ? convertArrayById(institutions) : [];
    console.log('usersArray', usersArray);
    console.log('institutionsArray', institutionsArray);
    const form = {
        titles: {
            index: 'Intézményi adminisztrátorok',
            create: 'Új adminisztrátor',
            update: 'Adminisztrátor módosítása',
        },
        keyField: 0,
        indexFields: [1, 0],
        fields: [
            {
                id: 0,
                required: true,
                name: 'institution',
                helper: 'Intézmény',
                q2: 'Intézmény',
                label: 'Intézmény',
                type: 5,
                values: institutions,
                valuesArray: institutionsArray,
                values_label: 'name',
                values_key: 'id',
                model:'institutions',
                modelId: 'id',
                modelLabel:'name'
            },
            {
                id: 1,
                required: true,
                name: 'user',
                helper: 'Felhasználó',
                q2: 'Felhasználó',
                label: 'Felhasználó',
                type: 3,
                values: users,
                valuesArray: usersArray,
                values_label: 'email',
                values_key: 'id',
            }
        ]
    };
    useFirestoreConnect([
        {
            collection: "admins",
            storeAs: 'admins'
        },
        {
            collection: "institutions",
            storeAs: 'institutions'
        },
        {
            collection: "users",
            // doc: authStatus.uid,
        }
    ]);

    const createElement = (newMeasure) => {
        if (typeof newMeasure.id === 'undefined') {
            addAdmins(newMeasure);
        } else {
            updateAdmins(newMeasure, newMeasure.id)
        }
    }

    const deleteElement = (newMeasure) => {
        deleteAdmins(newMeasure);
    }

    dispatch({ type: actions.SET_MENU, payload: 'Adminisztrátorok' })

    return (
        <Container className={classes.content}>
            <div>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        admins && users && Institutions ?
                            <FSPTable
                                model={admins}
                                classes={classes}
                                createElement={(measure) => createElement(measure)}
                                deleteElement={(measure) => deleteElement(measure)}
                                meta={{ institutions: institutions }}
                                form={form}
                                loading={users.length < 2}
                            /> :
                            <CircularProgress />
                    ))}
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        admins: state.firestore.ordered.admins,
        authStatus: state.firebase.auth,
        users: state.firestore.ordered.users,
        institutions: state.firestore.ordered.institutions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAdmins: (institution) => dispatch(addAdmins(institution)),
        deleteAdmins: (id) => dispatch(deleteAdmins(id)),
        updateAdmins: (institution, id) => dispatch(updateAdmins(institution, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admins);

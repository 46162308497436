import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Equalizer from '@material-ui/icons/Equalizer';
import ListaAlt from '@material-ui/icons/ListAlt';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  button: {
    margin: theme.spacing(1),
  },
  containedGreen: {
    color: '#fff',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
      '@media (hover: none)': {
        backgroundColor: green[500],
      },
    },
  },
  containedRed: {
    color: '#fff',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
}));

const Questionnaire = ({ questionnaire, deleteQuestionnaire }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1c-content'
          id='panel1c-header'
        >
          <div className={classes.column}>
            <Typography
              className={classes.heading}
              noWrap
              display='block'
            >
              {questionnaire.title}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              {questionnaire.state}
            </Typography>
          </div>

        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Typography>{questionnaire.description}</Typography>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
        <Button
            variant='contained'
            color='primary'
            className={classes.button}
            startIcon={<Equalizer />}
            onClick={() => {
              history.push(`/statistics/${questionnaire.id}`);
            }}
          >
            Elemzés
          </Button>
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            startIcon={<ListaAlt />}
            onClick={() => {
              history.push(`/evaluation/${questionnaire.id}`);
            }}
          >
            Kiértékelés
          </Button>
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            startIcon={<CreateIcon />}
            onClick={() => {
              history.push(`/edit/${questionnaire.id}`);
            }}
          >
            Szerkesztés
          </Button>
          <Button
            variant='contained'
            color='secondary'
            className={classes.containedRed}
            startIcon={<DeleteIcon />}
            onClick={() => {
              deleteQuestionnaire(questionnaire.id);
            }}
          >
            Törlés
            </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    </div>
  );
};

export default Questionnaire;

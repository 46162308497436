import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { addUsers, deleteUsers, updateUsers } from '../../store/actions/usersActions';
import Container from '@material-ui/core/Container';
import useStyles from "./dashboard/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions/actionTypes';
import FSPTable from '../common/FSPTable/FSPTable';

const UsersComponent = ({ users, authStatus, updateUsers, deleteUsers }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isEmpty, isLoaded } = authStatus;
    const form = {
        titles: {
            index: 'Felhasználók',
            create: 'Új felhasználó',
            update: 'Felhasználó módosítása',
        },
        keyField: 0,
        indexFields: [0, 1, 2, 3],
        fields: [
            {
                id: 0,
                name: 'id',
                required: true,
                helper: 'id',
                q2: 'id',
                label: 'id',
                type: 2,
            },
            {
                id: 1,
                required: true,
                name: 'email',
                helper: 'email',
                q2: 'email',
                label: 'email',
                type: 2,
            },
            {
                id: 2,
                required: true,
                name: 'firstName',
                helper: 'firstName',
                q2: 'firstName',
                label: 'firstName',
                type: 2,
            },
            {
                id: 3,
                required: true,
                name: 'lastName',
                helper: 'lastName',
                q2: 'lastName',
                label: 'lastName',
                type: 2,
            }
        ]
    };
    useFirestoreConnect([
        {
            collection: "users",
            storeAs: 'users'
        }
    ]);
    const createElement = (newMeasure) => {
        if (typeof newMeasure.id === 'undefined') {
            // addUsers(newMeasure);
        } else {
            updateUsers(newMeasure, newMeasure.id)
        }
    }

    const deleteElement = (newMeasure) => {
        deleteUsers(newMeasure);
    }

    dispatch({ type: actions.SET_MENU, payload: 'Felhasználók' })

    return (
        <Container className={classes.content}>
            <div>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        users ?
                            <FSPTable
                                model={users}
                                classes={classes}
                                createElement={(measure) => createElement(measure)}
                                deleteElement={(measure) => deleteElement(measure)}
                                form={form}
                            /> :
                            <CircularProgress />
                    ))}
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        users: state.firestore.ordered.users,
        authStatus: state.firebase.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // addUsers: (users) => dispatch(addUsers(users)),
        deleteUsers: (id) => dispatch(deleteUsers(id)),
        updateUsers: (users, id) => dispatch(updateUsers(users, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent);

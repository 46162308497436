import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Delete, Visibility, Edit } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import FSPTableDeleteDialog from './FSPTableDeleteDialog';

class FSPTableRow extends Component {
    constructor(props) {
        super();
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.state = {
            open: false,
        }
    }

    handleDelete = (e) => {
        console.log(e);
        //  console.log(element.id);
        this.props.deleteElement(e);
        this.setState({ open: false });
    }
    handleDeleteCancel = (e) => {
        this.setState({ open: false });
    }
    handleShow = (e) => {
        this.setState({ open: true });
    }
    render() {
        const { classes, element, form } = this.props;
        const a = element && element.participant ? Object.keys(element.participant).length + 1 : '1';
        // console.log('xxx', element);
        // console.log('xxx...', form);

        return (
            <>
                <TableRow>
                    {form && form.indexFields && form.indexFields.map((e, index) => (
                        <TableCell component="th" scope="row">
                            {
                                form.fields[e] &&
                                    form.fields[e].type == 3 &&
                                    form.fields[e].valuesArray &&
                                    form.fields[e].valuesArray[element[form.fields[e].name]] &&
                                    form.fields[e].values ?
                                    form.fields[e].valuesArray[element[form.fields[e].name]][form.fields[e].values_label] : ''
                            }
                            {form.fields[e] &&
                                form.fields[e].type == 5 &&
                                element[form.fields[e].name] &&
                                element[form.fields[e].name].map((ee, index) => (

                                    form.fields[e].valuesArray &&
                                        form.fields[e].valuesArray[ee] &&
                                        form.fields[e].values ?
                                        <div>{form.fields[e].valuesArray[ee][form.fields[e].values_label]} </div> : ''
                                ))
                            }
                            {form.fields[e] &&
                                form.fields[e].type != 3 &&
                                form.fields[e].type != 5 &&
                                form.fields[e].type != 6 &&
                                form.fields[e] &&
                                form.fields[e].name &&
                                element[form.fields[e].name] ?
                                element[form.fields[e].name] : ''
                            }
                            {form.fields[e] &&
                                form.fields[e].type == 6 &&
                                form.fields[e] &&
                                form.fields[e].name &&
                                element[form.fields[e].name] ?
                                <>{element[form.fields[e].name].toDate().toLocaleDateString('fr-CA')+' '+element[form.fields[e].name].toDate().toLocaleTimeString('en-EN')}</> : ''
                            }
                        </TableCell>
                    ))
                    }
                    {/* <TableCell style={{ maxWidth: '200', padding: '0' }} >
                        {element.om}
                    </TableCell>
                    <TableCell >
                        {element.name}
                    </TableCell> */}
                    {form.operations && (form.operations.delete || form.operations.update) &&
                        <TableCell>
                            {form.operations && form.operations.delete &&
                                <IconButton
                                    type='submit'
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => {
                                        // this.props.deleteElement(element.id);
                                        this.handleShow(element.id);
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            }


                            <FSPTableDeleteDialog
                                classes={classes}
                                open={this.state.open}
                                handleDelete={this.handleDelete}
                                handleDeleteCancel={this.handleDeleteCancel}
                                val={this.state.val}
                                form={form}
                                errors={this.state.errors}
                                id={element.id}
                            />
                            {form.operations && form.operations.update &&
                                <IconButton
                                    type='submit'
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => {
                                        this.props.handleEdit(element);
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            }
                        </TableCell>
                    }

                </TableRow>
            </>
        );
    }
}

export default FSPTableRow;
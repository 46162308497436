import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const setMenu = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.SET_MENU, id });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
}

export const setInstitution = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.SET_INSTITUTION, id });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
}

import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import Multiselect from 'multiselect-react-dropdown';
import zIndex from '@material-ui/core/styles/zIndex';

const FSPTableFormField = (params) => {
    const { field, handleChange, meta, handleChangeArray, val, errors, classes } = params;

    const onSelect = event => {
        let a = event.map((i) => i.id);
        handleChangeArray(field.name, a)
    };
    const onRemove = event => {
        let a = event.map((i) => i.id);
        handleChangeArray(field.name, a)
    }

    return (
        <div>
            {/* {field.type == 1 &&
                <div>
                    {field.type}
                    {field.q2}
                    <TextField id="standard-basic" label="Standard" />
                </div>
            } */}
            {field.type == 3 &&
                <div>
                    <div>
                        {field.q2}
                    </div>
                    <TextField
                        required
                        fullWidth
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        select
                        value={val[field.name]}
                        onChange={handleChange}
                    // helperText={field.helper}
                    >
                        {field.values.map((option) => (
                            <MenuItem key={option[field.values_key]} value={option[field.values_key]}>
                                {option[field.values_label]}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            }
            {field.type == 2 &&
                <div>
                    <div>
                        {field.q2}
                    </div>

                    <TextField
                        required={field.required ? true : false}
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        onChange={handleChange}
                        fullWidth
                        value={val[field.name]}
                    />
                </div>
            }
            {field.type == 4 &&
                <div>
                    <div>
                        {field.q2}
                    </div>

                    <TextField
                        required={field.required ? true : false}
                        id={"persid" + field.id}
                        name={field.id}
                        type="number"
                        inputProps={{ min: field.min, max: field.max, step: "1" }}
                        label={field.label}
                        onChange={handleChange}
                    // ref={ref.inputRef}
                    />
                </div>
            }
            {field.type == 5 &&
                <div>
                    <div>
                        {field.q2}
                    </div>
                    <Multiselect
                        id={"persid" + field.id}
                        name={field.id}
                        placeholder={field.q2}
                        style={{zIndex: '999999'}}
                        options={meta[field.model]} // Options to display in the dropdown
                        // selectedValues={val[field.name]}
                        selectedValues={val[field.name] ? meta[field.model].filter(function (item, index) {
                            return (val[field.name].indexOf(item.id) > -1);
                        }) : []}
                        // {val[field.id]} // Preselected value to persist in dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                    // isObject={false}
                    />
                </div>
            }
            {errors.length > 0 && field.required &&
                <span className={classes.error}>{errors}</span>
            }
        </div>
    );
};

export default FSPTableFormField;
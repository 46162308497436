import firebase from 'firebase/app';
import * as actions from './actionTypes';

// export const addUsers = (data) => async (dispatch, getState) => {
//   try {
//     dispatch({ type: actions.ADD_TODO_START });
//     const uid = getState().firebase.auth.uid;
//     const firestore = firebase.firestore();
//     await firestore.collection('users').add({
//       ...data,
//       authorId: uid,
//       createdAt: new Date(),
//       updatedAt: new Date(),
//     });
//     dispatch({ type: actions.ADD_TODO_SUCESS });
//   } catch (err) {
//     dispatch({ type: actions.ADD_TODO_FAIL, err });
//   }
// };

export const updateUsers = (data, id) => async (dispatch) => {
    try {
        dispatch({ type: actions.UPDATE_TODO_START });
        const firestore = firebase.firestore();
        await firestore.collection('users').doc(id).update({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            updatedAt: new Date(),
        });
        dispatch({ type: actions.UPDATE_TODO_SUCESS });
    } catch (err) {
        dispatch({ type: actions.UPDATE_TODO_FAIL, err });
    }
};

export const deleteUsers = (id) => async (dispatch) => {
    try {
        dispatch({ type: actions.DELETE_TODO_START });
        const firestore = firebase.firestore();
        await firestore.collection('users').doc(id).delete();
        dispatch({ type: actions.DELETE_TODO_SUCESS });
    } catch (err) {
        dispatch({ type: actions.DELETE_TODO_FAIL, err });
    }
};

import React, { Component } from 'react';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

class FSPTableFooter extends Component {

    render() {
        return (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        // style={{ width: '500px', display: 'flex' }}
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={this.props.model.length}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={(e, a) => this.props.handleChangePage(e, a)}
                        onChangeRowsPerPage={(e) => this.props.handleChangeRowsPerPage(e)}
                    />
                </TableRow>
            </TableFooter>
        );
    }
}

export default FSPTableFooter;
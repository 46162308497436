import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import Questionnaire from '../projects/Questionnaire';
import { deleteQuestionnaire } from '../../../store/actions/questionnaireActions';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import PermissionTable from '../projects/PermissionTable';
import useStyles from "./styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import { Delete, Add } from '@material-ui/icons';

const PermissionsEditor = ({ permissions, users, questionnaires, authStatus, deleteQuestionnaire }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isEmpty, isLoaded } = authStatus;

    useFirestoreConnect([
        {
            collection: "user_questionnaire_permission",
            // doc: authStatus.uid,
            //    where: [
            //     ['user', '==', authStatus.uid],
            // ],
            storeAs: 'permissions'
        },
        {
            collection: "users",
            // doc: authStatus.uid,
        },
        {
            collection: 'questionnaires',
            orderBy: ['createdAt', 'asc']
        },
    ]);
    console.log(permissions);
    console.log(users);
    console.log(questionnaires);
    dispatch({ type: actions.SET_MENU, payload: 'Résztvevők' })
    return (
        <Container className={classes.content}>
            <div>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        permissions && users && questionnaires ?
                            <PermissionTable
                                permissions={permissions}
                                users={users}
                                questionnaires={questionnaires}
                                classes={classes}
                            /> :
                            <CircularProgress  />
                    ))}
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        permissions: state.firestore.ordered.permissions,
        questionnaires: state.firestore.ordered.questionnaires,
        users: state.firestore.ordered.users,
        authStatus: state.firebase.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteQuestionnaire: (id) => dispatch(deleteQuestionnaire(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsEditor);

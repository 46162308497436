import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signIn } from '../../../store/actions/authActions';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Logo from './../../../../public/logo.png'
const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
  },
  marginButton: {
    marginTop: theme.spacing(4),
  },
  rounded: {
    color: '#fff',
    backgroundColor: green[500],
    marginTop: theme.spacing(4),
  },
  standardError: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  circularProgressWhite: {
    color: '#fff',
  },
  logo: {
    width: '200px',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

const SignIn = ({ userStatus, authStatus, signIn }) => {
  const { isEmpty, isLoaded } = authStatus;
  const { loading, error } = userStatus;

  const classes = useStyles();

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    setCredentials({ ...credentials, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(credentials);
  };

  if (isLoaded && !isEmpty) return <Redirect to='/' />;

  if (isLoaded && isEmpty) {
    return (
      <Container maxWidth='sm'>
        <Box display='flex' flexDirection='column' alignItems='center'>

          <img
            // alt={translate('education_2030')}
            className={classes.logo}
            src={'logo.jpg'}
          />
          {/* <Avatar variant='rounded' className={classes.rounded}>
          
            <ExitToAppIcon />
          </Avatar> */}
          <Typography variant='h5' color='inherit' className={classes.margin}>
            TSZK - Intézményi statisztika
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            display='flex'
            flexDirection='row'
            flexWrap='wrap'
            alignItems='center'
            justifyContent='space-between'
          >
            <TextField
              required
              type='email'
              id='email'
              label='Email'
              fullWidth
              value={credentials.email}
              onChange={handleChange}
              className={classes.margin}
            />
            <TextField
              fullWidth
              required
              id='password'
              label='Password'
              type='password'
              value={credentials.password}
              onChange={handleChange}
              className={classes.margin}
            />
            <Button
              fullWidth
              type='submit'
              variant='contained'
              color='primary'
              className={classes.marginButton}
            >
              {loading ? (
                <CircularProgress className={classes.circularProgressWhite} />
              ) : (
                'Bejelentkezés'
              )}
            </Button>
            {error ? (
              <Alert
                variant='filled'
                severity='error'
                className={classes.standardError}
              >
                Invalid Email or Password
              </Alert>
            ) : null}
          </Box>
        </form>
      </Container>
    );
  } else return <></>;
};

const mapStateToProps = (state) => {
  return {
    userStatus: state.auth,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (credentials) => dispatch(signIn(credentials)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

export const leaders = {
    'igazgató, intézményegység vezető': 'vezető',
    'igazgató-helyettes, intézményegység-helyettes': 'vezető',
    'igazgató': 'vezető',
    'igazgató-helyettes': 'vezető',
    'osztályfőnök': 'nem vezető',
    'munkaközösség-vezető': 'vezető',
    'nem oktató munkatárs': 'nem vezető',
    'nem vezető beosztású pedagógus': 'nem vezető',
    'egyéb, éspedig:': 'nem vezető',
};
export const everybody = {
    'igazgató, intézményegység vezető': 'everybody',
    'igazgató-helyettes, intézményegység-helyettes': 'everybody',
    'igazgató': 'everybody',
    'igazgató-helyettes': 'everybody',
    'osztályfőnök': 'everybody',
    'munkaközösség-vezető': 'everybody',
    'nem oktató munkatárs': 'everybody',
    'nem vezető beosztású pedagógus': 'everybody',
    'egyéb, éspedig:': 'everybody',
};

export const subscaleMaxValue = [11, 9, 6, 14, 6, 8, 6, 8, 12];

export const data_sample = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];
export const chartColors = [
    '#82ca9d',
    '#4285f4',
    '#ea4335',
    '#fbbc04',
    '#34a853',
    '#ff6d01',
    '#46bdc6',
    '#7baaf7',
    '#f07b72',
    '#fcd04f',
    '#71c287',
    '#ff994d',
    '#7ed1d7',
    '#6038de',
    '#b1b5dc',
];

export const titles = {
    vezető: 'vezető',
    nem_vezető: 'nem vezető',
    value: 'Érték',
    percent: 'Százalék',
    scale1_value: 'Emberi kapacitás',
    scale1_evaluation_value: 'Emberi kapacitás',
    scale2_value: 'Interperszonális kapacitás',
    scale2_evaluation_value: 'Interperszonális kapacitás',
    scale3_value: 'Szervezeti kapacitás',
    scale3_evaluation_value: 'Szervezeti kapacitás',
    scale4_value: 'Hálózati kapacitás',
    scale4_evaluation_value: 'Hálózati kapacitás',
    scale1_percent: 'Emberi kapacitás %',
    scale2_percent: 'Interperszonális kapacitás %',
    scale3_percent: 'Szervezeti kapacitás %',
    scale4_percent: 'Hálózati kapacitás %',
    subscale1_value: 'Ön- és továbbképzés',
    subscale2_value: 'Proaktivitás',
    subscale3_value: 'Bizalom és nyitottság',
    subscale4_value: 'Reflektivitás',
    subscale5_value: 'Tudásmenedzsment',
    subscale6_value: 'Támogatás és felhatalmazás',
    subscale7_value: 'Tanuló- és tanulásközpontú jövőkép',
    subscale8_value: 'Rendszergondolkodás',
    subscale9_value: 'Hálózatépítés és -fenntartás',
    subscale1_percent: 'Ön- és továbbképzés %',
    subscale2_percent: 'Proaktivitás %',
    subscale3_percent: 'Bizalom és nyitottság %',
    subscale4_percent: 'Reflektivitás %',
    subscale5_percent: 'Tudásmenedzsment %',
    subscale6_percent: 'Támogatás és felhatalmazás %',
    subscale7_percent: 'Tanuló- és tanulásközpontú jövőkép %',
    subscale8_percent: 'Rendszergondolkodás %',
    subscale9_percent: 'Hálózatépítés és -fenntartás %',
    answer1: 'Válasz 1',
    answer2: 'Válasz 2',
    answer3: 'Válasz 3',
    answer4: 'Válasz 4',
    answer5: 'Válasz 5',
    answer6: 'Válasz 6',
    answer7: 'Válasz 7',
    answer8: 'Válasz 8',
    answer9: 'Válasz 9',
    answer10: 'Válasz 10',
    answer11: 'Válasz 11',
    answer12: 'Válasz 12',
    answer13: 'Válasz 13',
    answer14: 'Válasz 14',
    answer15: 'Válasz 15',
    answer16: 'Válasz 16',
    answer17: 'Válasz 17',
    answer18: 'Válasz 18',
    answer19: 'Válasz 19',
    answer20: 'Válasz 20',
    answer21: 'Válasz 21',
    answer22: 'Válasz 22',
    answer23: 'Válasz 23',
    answer24: 'Válasz 24',
    answer25: 'Válasz 25',
    answer26: 'Válasz 26',
    answer27: 'Válasz 27',
    answer28: 'Válasz 28',
    answer29: 'Válasz 29',
    answer30: 'Válasz 30',
    answer31: 'Válasz 31',
    answer32: 'Válasz 32',
    answer33: 'Válasz 33',
    answer34: 'Válasz 34',
    answer35: 'Válasz 35',
    answer36: 'Válasz 36',
    answer37: 'Válasz 37',
    answer38: 'Válasz 38',
    answer39: 'Válasz 39',
    answer40: 'Válasz 40',
    answer41: 'Válasz 41',
    answer42: 'Válasz 42',
    answer43: 'Válasz 43',
    answer44: 'Válasz 44',
    answer45: 'Válasz 45',
    answer46: 'Válasz 46',
    answer47: 'Válasz 47',
    answer48: 'Válasz 48',
    answer49: 'Válasz 49',
    answer50: 'Válasz 50',
    answer51: 'Válasz 51',
    answer52: 'Válasz 52',
    answer53: 'Válasz 53',
    answer54: 'Válasz 54',
    answer55: 'Válasz 55',
    answer56: 'Válasz 56',
    answer57: 'Válasz 57',
    answer58: 'Válasz 58',
    answer59: 'Válasz 59',
    answer60: 'Válasz 60',
    answer61: 'Válasz 61',
    answer62: 'Válasz 62',
    answer63: 'Válasz 63',
    answer64: 'Válasz 64',
    answer65: 'Válasz 65',
    answer66: 'Válasz 66',
    answer67: 'Válasz 67',
    answer68: 'Válasz 68',
    answer69: 'Válasz 69',
    answer70: 'Válasz 70',
    answer71: 'Válasz 71',
    answer72: 'Válasz 72',
    answer73: 'Válasz 73',
    answer74: 'Válasz 74',
    answer75: 'Válasz 75',
    answer76: 'Válasz 76',
    answer77: 'Válasz 77',
    answer78: 'Válasz 78',
    answer79: 'Válasz 79',
    answer80: 'Válasz 80',
};
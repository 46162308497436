import React, { Component } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

class Filled extends Component {
    evaluate = () => {
        let result = {};
        let params = [];
        if (this.props.questionnaire.scales.api) {
            result[1] = '';
            // id = values
            // console.log('cale.name', this.props.questionnaire.scales.value);
            // console.log('cale.name', this.props.filled.answers);
            let values='';
            Object.keys(this.props.filled.answers).forEach(key => {
                // console.log(this.props.filled.answers[key].toString());
                values=values+this.props.filled.answers[key].toString();
            });
            // console.log(this.props.filled.answers.join(''));
            console.log(values);
            // parameters
            // console.log('cale.name',);
            // console.log('cale.name', this.props.questionnaire.personal);
            // console.log('cale.name', this.props.filled.personal);
            params['id'] = values;
            Object.keys(this.props.questionnaire.scales.parameters).forEach((key) => {
                params[this.props.questionnaire.scales.parameters[key]] = this.props.filled.personal[key];
            });
            var url = new URL(this.props.questionnaire.scales.api);
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
            // console.log(params);
            // API
            console.log(url)
            fetch(url)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log('result', result);
                        this.props.updateEvaluations(result, this.props.filled.id);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log('ERROR', error);

                    }
                )
        } else {
            this.props.questionnaire.scales.forEach(scale => {
                let value = 0;
                // evaluation scales
                let sub_result = {};
                scale.children.forEach(sub => {
                    let s = 0;
                    // evaluation subscales
                    sub.values.forEach(value => {
                        s += this.props.filled.answers[value];
                    });
                    // alskála értéke
                    console.log(sub.name, s);
                    console.log(sub.name, sub.evaluation);
                    value += s;
                    sub_result[sub.id] = {
                        value: s
                    };
                });
                // skála értéke
                console.log(scale.name, value);
                console.log(scale.name, scale.evaluation);

                // evaluation 
                let evaluation = {};
                scale.evaluation.forEach(e => {
                    console.log(e);
                    if (e.min <= value && value <= e.max) {
                        evaluation = e;
                    }
                });
                result[scale.id] = {
                    value: value,
                    evaluation: evaluation,
                    sub_scales: sub_result

                };
            });
            this.props.updateEvaluations(result, this.props.filled.id)
        }

    }
    render() {

        const { classes, n } = this.props;
        const ready = this.props.filled.answers ? Object.keys(this.props.filled.answers).length : 0;
        const evaluated = this.props.filled.status === 'evaluated';

        return (
            <div className={classes.root}>
                {this.props.users[this.props.filled.authorId] && (
                    <ExpansionPanel expanded={true} className={classes.questionPanel} >
                        <ExpansionPanelSummary className={evaluated ? classes.containedGreen : ready == n ? classes.containedOrange : classes.containedRed} >
                            {this.props.users[this.props.filled.authorId].email}-{this.props.filled.id}- {this.props.filled.status}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails >
                            {this.props.users[this.props.filled.authorId].firstName} {this.props.users[this.props.filled.authorId].lastName}-
                            {this.props.filled.personal['1']} {ready + '/' + this.props.n}
                        </ExpansionPanelDetails>
                        <Divider />
                        <ExpansionPanelActions>
                            <Button
                                variant='contained'
                                className={classes.button}
                                disabled={ready == n ? false : true}
                                onClick={(e) => { this.evaluate() }}
                                color={ready == n ? 'primary' : 'contained'}
                            >
                                Kiértékel
                            </Button>
                            <Button
                                variant='contained'
                                className={classes.button}
                                // disabled={ready == n ? false : true}
                                onClick={() => {
                                    this.props.history.push(`/filledview/${this.props.filled.id}`);
                                }}
                                color={ready == n ? 'primary' : 'contained'}
                            >
                                Eredmények
                            </Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                )
                }
            </div>
        );
    }
}

export default withRouter(Filled);
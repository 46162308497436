import React from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { ReferenceLine, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Text, Label, PieChart, Pie } from 'recharts';
import StatisticsBarChartTooltip from './StatisticsBarChartTooltip';
import CustomizedTick from './CustomizedTick';
import { subscaleMaxValue, chartColors, titles, leaders, everybody } from './FilledStatsConsts';
import { createBaseData, groupingData, evaluate } from './FilledStatsUtility'
import TszkIndex from './TszkIndex';
import TszkBarChart from './TszkBarChart';
import { connect } from 'react-redux';

const CustomizedAxisTick = (props) => {
    const shortName = {
        'igazgató, intézményegység vezető': 'igazgató',
        'igazgató-helyettes, intézményegység-helyettes': 'igazgató-helyettes',
        'igazgató': 'igazgató',
        'igazgató-helyettes': 'igazgató-helyettes',
        'osztályfőnök': 'osztályfőnök',
        'munkaközösség-vezető': 'm.vezető',
        'nem oktató munkatárs': 'nem okt.',
        'nem vezető beosztású pedagógus': 'pedagógus',
        'egyéb, éspedig:': 'egyéb',
    }
    const { x, y, width, height, value, payload } = props;

    return (
        <text width={100} x={x} y={y + 11} fill="#000" textAnchor="middle" dominantBaseline="middle">
            <tspan textAnchor="middle">{shortName[payload.value]}</tspan>
        </text>
    )
};
const CustomizedAxisTick2 = (props) => {
    const shortName = {
        'Ön- és továbbképzés %': 'Önképzés',
        'Proaktivitás %': 'Proaktivitás',
        'Bizalom és nyitottság %': 'Bizalom',
        'Reflektivitás %': 'Reflektivitás.',
        'Tudásmenedzsment %': 'Tudásmenedzsm.',
        'Támogatás és felhatalmazás %': 'Támogatás',
        'Tanuló- és tanulásközpontú jövőkép %': 'Jövőkép',
        'Rendszergondolkodás %': 'Rendszergond.',
        'Hálózatépítés és -fenntartás %': 'Hálózatépítés',
    }
    const { x, y, width, height, value, payload } = props;

    return (
        <text width={100} x={x} y={y + 11} fill="#000" textAnchor="middle" dominantBaseline="middle">
            <tspan textAnchor="middle">{shortName[payload.value]}</tspan>
        </text>
    )
};

const getXValue = data => {
    return data.value.x;
};
// groupByYear['sum'] ->data
// questionnaire.personal[1].values -> values
// 
const createChartData = (questionnaire, data, values) => {
    let chartData = [];
    if (questionnaire && data) {
        Object.keys(values).forEach(key => {
            let element = [];
            let name = values[key]['label'];
            element['name'] = name;
            element['Kitöltők száma'] = data[name]
                ? data[name]['db'] : 0;
            element['TSZK Index'] = data[name]
                ? data[name]['percent'] : 0;

            chartData.push(element);
        }
        );
    };
    return chartData;
}

const renderCustomizedLabel2 = (props) => {
    const { x, y, width, height, value } = props;
    return (
        <g>
            <text x={x + width / 2} y={y + height / 2} fill="#fff" textAnchor="middle" dominantBaseline="middle">
                {props.value != '0' ? `${parseInt(value)}` : ''}
            </text>
        </g>
    );
}

const FilledStats = (params) => {
    const { dataLoading, form, classes, model, questionnaire, institutions } = params;
    let base = [];
    let scaleChartData = [];
    let subScaleChartData = [];
    let allData = [];
    let groupByYear = [];
    let groupByPosition = [];
    let groupByLeader = [];
    let dataForYearChart = [];
    let dataForAllChart = [];
    let dataForPositionChart = [];
    let dataForLeaderChart = [];
    let dataForLeaderColumns2Chart = [];
    let dataForLeaderColumns3Chart = [];

    if (!dataLoading) {
        base = createBaseData(model, questionnaire, institutions);
        console.log('BASE', base);

        allData = groupingData(base,
            'position_txt',
            ['value', 'percent', 'scale1_value', 'scale1_evaluation_value', 'scale2_value', 'scale2_evaluation_value', 'scale3_value', 'scale3_evaluation_value', 'scale4_value', 'scale4_evaluation_value', 'scale1_percent', 'scale2_percent', 'scale3_percent', 'scale4_percent', 'subscale1_value', 'subscale2_value', 'subscale3_value', 'subscale4_value', 'subscale5_value', 'subscale6_value', 'subscale7_value', 'subscale8_value', 'subscale9_value', 'subscale1_percent', 'subscale2_percent', 'subscale3_percent', 'subscale4_percent', 'subscale5_percent', 'subscale6_percent', 'subscale7_percent', 'subscale8_percent', 'subscale9_percent',
                // 'answer1', 'answer2', 'answer3', 'answer4', 'answer5', 'answer6', 'answer7', 'answer8', 'answer9', 'answer10', 'answer11', 'answer12', 'answer13', 'answer14', 'answer15', 'answer16', 'answer17', 'answer18', 'answer19', 'answer20', 'answer21', 'answer22', 'answer23', 'answer24', 'answer25', 'answer26', 'answer27', 'answer28', 'answer29', 'answer30', 'answer31', 'answer32', 'answer33', 'answer34', 'answer35', 'answer36', 'answer37', 'answer38', 'answer39', 'answer40', 'answer41', 'answer42', 'answer43', 'answer44', 'answer45', 'answer46', 'answer47', 'answer48', 'answer49', 'answer50', 'answer51', 'answer52', 'answer53', 'answer54', 'answer55', 'answer56', 'answer57', 'answer58', 'answer59', 'answer60', 'answer61', 'answer62', 'answer63', 'answer64', 'answer65', 'answer66', 'answer67', 'answer68', 'answer69', 'answer70', 'answer71', 'answer72', 'answer73', 'answer74', 'answer75', 'answer76', 'answer77', 'answer78', 'answer79', 'answer80'
            ],
            everybody
        );
        console.log('INDEX', allData);

        groupByYear = groupingData(base,
            'years_txt',
            ['value', 'percent', 'scale1_value', 'scale1_evaluation_value', 'scale2_value', 'scale2_evaluation_value', 'scale3_value', 'scale3_evaluation_value', 'scale4_value', 'scale4_evaluation_value', 'scale1_percent', 'scale2_percent', 'scale3_percent', 'scale4_percent', 'subscale1_value', 'subscale2_value', 'subscale3_value', 'subscale4_value', 'subscale5_value', 'subscale6_value', 'subscale7_value', 'subscale8_value', 'subscale9_value', 'subscale1_percent', 'subscale2_percent', 'subscale3_percent', 'subscale4_percent', 'subscale5_percent', 'subscale6_percent', 'subscale7_percent', 'subscale8_percent', 'subscale9_percent',
                // 'answer1', 'answer2', 'answer3', 'answer4', 'answer5', 'answer6', 'answer7', 'answer8', 'answer9', 'answer10', 'answer11', 'answer12', 'answer13', 'answer14', 'answer15', 'answer16', 'answer17', 'answer18', 'answer19', 'answer20', 'answer21', 'answer22', 'answer23', 'answer24', 'answer25', 'answer26', 'answer27', 'answer28', 'answer29', 'answer30', 'answer31', 'answer32', 'answer33', 'answer34', 'answer35', 'answer36', 'answer37', 'answer38', 'answer39', 'answer40', 'answer41', 'answer42', 'answer43', 'answer44', 'answer45', 'answer46', 'answer47', 'answer48', 'answer49', 'answer50', 'answer51', 'answer52', 'answer53', 'answer54', 'answer55', 'answer56', 'answer57', 'answer58', 'answer59', 'answer60', 'answer61', 'answer62', 'answer63', 'answer64', 'answer65', 'answer66', 'answer67', 'answer68', 'answer69', 'answer70', 'answer71', 'answer72', 'answer73', 'answer74', 'answer75', 'answer76', 'answer77', 'answer78', 'answer79', 'answer80'
            ],
            //  leaders
        );
        groupByPosition = groupingData(base,
            'position_txt',
            ['value', 'percent', 'scale1_value', 'scale1_evaluation_value', 'scale2_value', 'scale2_evaluation_value', 'scale3_value', 'scale3_evaluation_value', 'scale4_value', 'scale4_evaluation_value', 'scale1_percent', 'scale2_percent', 'scale3_percent', 'scale4_percent', 'subscale1_value', 'subscale2_value', 'subscale3_value', 'subscale4_value', 'subscale5_value', 'subscale6_value', 'subscale7_value', 'subscale8_value', 'subscale9_value', 'subscale1_percent', 'subscale2_percent', 'subscale3_percent', 'subscale4_percent', 'subscale5_percent', 'subscale6_percent', 'subscale7_percent', 'subscale8_percent', 'subscale9_percent',
                // 'answer1', 'answer2', 'answer3', 'answer4', 'answer5', 'answer6', 'answer7', 'answer8', 'answer9', 'answer10', 'answer11', 'answer12', 'answer13', 'answer14', 'answer15', 'answer16', 'answer17', 'answer18', 'answer19', 'answer20', 'answer21', 'answer22', 'answer23', 'answer24', 'answer25', 'answer26', 'answer27', 'answer28', 'answer29', 'answer30', 'answer31', 'answer32', 'answer33', 'answer34', 'answer35', 'answer36', 'answer37', 'answer38', 'answer39', 'answer40', 'answer41', 'answer42', 'answer43', 'answer44', 'answer45', 'answer46', 'answer47', 'answer48', 'answer49', 'answer50', 'answer51', 'answer52', 'answer53', 'answer54', 'answer55', 'answer56', 'answer57', 'answer58', 'answer59', 'answer60', 'answer61', 'answer62', 'answer63', 'answer64', 'answer65', 'answer66', 'answer67', 'answer68', 'answer69', 'answer70', 'answer71', 'answer72', 'answer73', 'answer74', 'answer75', 'answer76', 'answer77', 'answer78', 'answer79', 'answer80'
            ],
            //  leaders
        );
        groupByLeader = groupingData(base,
            'position_txt',
            ['value', 'percent', 'scale1_value', 'scale1_evaluation_value', 'scale2_value', 'scale2_evaluation_value', 'scale3_value', 'scale3_evaluation_value', 'scale4_value', 'scale4_evaluation_value', 'scale1_percent', 'scale2_percent', 'scale3_percent', 'scale4_percent', 'subscale1_value', 'subscale2_value', 'subscale3_value', 'subscale4_value', 'subscale5_value', 'subscale6_value', 'subscale7_value', 'subscale8_value', 'subscale9_value', 'subscale1_percent', 'subscale2_percent', 'subscale3_percent', 'subscale4_percent', 'subscale5_percent', 'subscale6_percent', 'subscale7_percent', 'subscale8_percent', 'subscale9_percent',
                // 'answer1', 'answer2', 'answer3', 'answer4', 'answer5', 'answer6', 'answer7', 'answer8', 'answer9', 'answer10', 'answer11', 'answer12', 'answer13', 'answer14', 'answer15', 'answer16', 'answer17', 'answer18', 'answer19', 'answer20', 'answer21', 'answer22', 'answer23', 'answer24', 'answer25', 'answer26', 'answer27', 'answer28', 'answer29', 'answer30', 'answer31', 'answer32', 'answer33', 'answer34', 'answer35', 'answer36', 'answer37', 'answer38', 'answer39', 'answer40', 'answer41', 'answer42', 'answer43', 'answer44', 'answer45', 'answer46', 'answer47', 'answer48', 'answer49', 'answer50', 'answer51', 'answer52', 'answer53', 'answer54', 'answer55', 'answer56', 'answer57', 'answer58', 'answer59', 'answer60', 'answer61', 'answer62', 'answer63', 'answer64', 'answer65', 'answer66', 'answer67', 'answer68', 'answer69', 'answer70', 'answer71', 'answer72', 'answer73', 'answer74', 'answer75', 'answer76', 'answer77', 'answer78', 'answer79', 'answer80'
            ],
            leaders
        );
        console.log('Pozíció', groupByPosition);
        console.log('Vezető-nem vezető', groupByLeader);
        console.log('Évek', groupByYear);

        dataForAllChart = [
            {
                name: 'Összes',
                index:
                    allData && allData.mean && allData.mean.everybody ? allData.mean.everybody.percent : 0,
            }
        ];

        scaleChartData = [];
        scaleChartData['name'] = 'Sklálák';
        scaleChartData['name2'] = 'Alsklálák';

        ['scale1_percent', 'scale2_percent', 'scale3_percent', 'scale4_percent',
            'subscale1_percent', 'subscale2_percent', 'subscale3_percent', 'subscale4_percent',
            'subscale5_percent', 'subscale6_percent', 'subscale7_percent', 'subscale8_percent',
            'subscale9_percent']
            .forEach(key => {
                scaleChartData[titles[key]] = allData && allData.mean && allData.mean.everybody ? allData.mean.everybody[key] : 0;
            })
        subScaleChartData = [];
        ['subscale1_percent', 'subscale2_percent', 'subscale3_percent', 'subscale4_percent',
            'subscale5_percent', 'subscale6_percent', 'subscale7_percent', 'subscale8_percent',
            'subscale9_percent']
            .forEach(key => {
                let x = allData && allData.mean && allData.mean.everybody ? allData.mean.everybody[key] : 0;
                subScaleChartData.push({ name: titles[key], value: parseInt(x) });
            })

        dataForYearChart = [];
        if (questionnaire && groupByYear.sum) {
            Object.keys(questionnaire.personal[1].values).forEach(q => {
                let element = [];
                element['name'] = questionnaire.personal[1].values[q]['label'];
                element['Kitöltők száma'] = groupByYear['sum'][questionnaire.personal[1].values[q]['label']]
                    ? groupByYear['sum'][questionnaire.personal[1].values[q]['label']]['db'] : 0;
                element['TSZK Index'] = groupByYear['sum'][questionnaire.personal[1].values[q]['label']]
                    ? groupByYear['mean'][questionnaire.personal[1].values[q]['label']]['percent'] : 0;

                dataForYearChart.push(element);
            }
            );
        };

        dataForPositionChart = [];
        if (questionnaire && groupByPosition.sum) {
            Object.keys(questionnaire.personal[2].values).forEach(q => {
                let element = [];
                element['name'] = questionnaire.personal[2].values[q]['label'];
                element['Kitöltők száma'] = groupByPosition['sum'][questionnaire.personal[2].values[q]['label']]
                    ? groupByPosition['sum'][questionnaire.personal[2].values[q]['label']]['db'] : 0;
                element['TSZK Index'] = groupByPosition['sum'][questionnaire.personal[2].values[q]['label']]
                    ? groupByPosition['mean'][questionnaire.personal[2].values[q]['label']]['percent'] : 0;
                dataForPositionChart.push(element);
            }
            );
        };
        dataForLeaderChart = [];
        if (questionnaire && groupByLeader.sum) {
            ['vezető', 'nem_vezető'].forEach(field => {
                let results = [];
                let tfield = titles[field];
                ['value', 'percent', 'scale1_value', 'scale1_evaluation_value', 'scale2_value', 'scale2_evaluation_value', 'scale3_value', 'scale3_evaluation_value', 'scale4_value', 'scale4_evaluation_value', 'scale1_percent', 'scale2_percent', 'scale3_percent', 'scale4_percent', 'subscale1_value', 'subscale2_value', 'subscale3_value', 'subscale4_value', 'subscale5_value', 'subscale6_value', 'subscale7_value', 'subscale8_value', 'subscale9_value', 'subscale1_percent', 'subscale2_percent', 'subscale3_percent', 'subscale4_percent', 'subscale5_percent', 'subscale6_percent', 'subscale7_percent', 'subscale8_percent', 'subscale9_percent',
                    // 'answer1', 'answer2', 'answer3', 'answer4', 'answer5', 'answer6', 'answer7', 'answer8', 'answer9', 'answer10', 'answer11', 'answer12', 'answer13', 'answer14', 'answer15', 'answer16', 'answer17', 'answer18', 'answer19', 'answer20', 'answer21', 'answer22', 'answer23', 'answer24', 'answer25', 'answer26', 'answer27', 'answer28', 'answer29', 'answer30', 'answer31', 'answer32', 'answer33', 'answer34', 'answer35', 'answer36', 'answer37', 'answer38', 'answer39', 'answer40', 'answer41', 'answer42', 'answer43', 'answer44', 'answer45', 'answer46', 'answer47', 'answer48', 'answer49', 'answer50', 'answer51', 'answer52', 'answer53', 'answer54', 'answer55', 'answer56', 'answer57', 'answer58', 'answer59', 'answer60', 'answer61', 'answer62', 'answer63', 'answer64', 'answer65', 'answer66', 'answer67', 'answer68', 'answer69', 'answer70', 'answer71', 'answer72', 'answer73', 'answer74', 'answer75', 'answer76', 'answer77', 'answer78', 'answer79', 'answer80'
                ]
                    .forEach(value => {
                        results[titles[value]] = groupByLeader['mean'] && groupByLeader['mean'][tfield] && groupByLeader['mean'][tfield][value] ? groupByLeader['mean'][tfield][value] : 0
                    })
                results['name'] = tfield;
                results['Kitöltők száma'] = groupByLeader['mean'][tfield]
                    ? groupByLeader['mean'][tfield]['db'] : 0;
                results['TSZK Index'] = groupByLeader['mean'][tfield]
                    ? groupByLeader['mean'][tfield]['percent'] : 0;
                dataForLeaderChart.push(results);
            }
            );
        };
        dataForLeaderColumns2Chart = [];
        if (questionnaire && groupByLeader.sum) {
            ['scale1_percent', 'scale2_percent', 'scale3_percent', 'scale4_percent']
                .forEach(value => {
                    let results = [];
                    let tvalue = titles[value];
                    ['vezető', 'nem_vezető']
                        .forEach(field => {
                            let tfield = titles[field];
                            results[titles[field]] = groupByLeader['mean'] && groupByLeader['mean'][tfield] && groupByLeader['mean'][tfield][value] ? groupByLeader['mean'][tfield][value] : 0
                        })
                    results['name'] = tvalue;
                    dataForLeaderColumns2Chart.push(results);
                }
                );
        };
        dataForLeaderColumns3Chart = [];
        if (questionnaire && groupByLeader.sum) {
            [
                'subscale1_percent',
                'subscale2_percent',
                'subscale3_percent',
                'subscale4_percent',
                'subscale5_percent',
                'subscale6_percent',
                'subscale7_percent',
                'subscale8_percent',
                'subscale9_percent'
            ]
                .forEach(value => {
                    let results = [];
                    let tvalue = titles[value];
                    ['vezető', 'nem_vezető']
                        .forEach(field => {
                            let tfield = titles[field];
                            results[titles[field]] = groupByLeader['mean'] && groupByLeader['mean'][tfield] && groupByLeader['mean'][tfield][value] ? groupByLeader['mean'][tfield][value] : 0
                        })
                    results['name'] = tvalue;
                    dataForLeaderColumns3Chart.push(results);
                }
                );
        };
    }

    return (
        <div>
            <h1>Statisztika</h1>
            <h3>Kitöltők száma: {base ? base.length : '0'}</h3>

            <TszkIndex
                height={85}
                yAxisWidth={50}
                title={'TSZK Index'}
                data={dataForAllChart}
                dataKey="index"
            />

            <ResponsiveContainer width="80%" height={300}>
                <BarChart
                    width={800}
                    height={300}
                    data={[scaleChartData]}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={titles["scale1_percent"]} fill={chartColors[0]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["scale2_percent"]} fill={chartColors[1]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["scale3_percent"]} fill={chartColors[2]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["scale4_percent"]} fill={chartColors[3]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

            <ResponsiveContainer width="80%" height={300}>
                <BarChart
                    width={800}
                    height={300}
                    data={[scaleChartData]}

                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name2" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={titles["subscale1_percent"]} fill={chartColors[0]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale2_percent"]} fill={chartColors[1]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale3_percent"]} fill={chartColors[2]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale4_percent"]} fill={chartColors[3]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale5_percent"]} fill={chartColors[4]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale6_percent"]} fill={chartColors[5]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale7_percent"]} fill={chartColors[6]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale8_percent"]} fill={chartColors[7]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale9_percent"]} fill={chartColors[8]} >
                        <LabelList dataValues={[scaleChartData]} content={renderCustomizedLabel2} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

            <ResponsiveContainer width="80%" height={400}>
                <PieChart
                // width={800}
                // height={800}
                >
                    <Pie
                        // width={800}
                        // height={800}
                        data={subScaleChartData}
                        cx="50%"
                        cy="50%"
                        label
                        labelLine={false}
                        // label={renderCustomizedLabel}
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {subScaleChartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
                        ))}
                        {/* <LabelList dataValues={a} /> */}
                    </Pie>
                    <Legend />
                </PieChart>
            </ResponsiveContainer>

            <TszkBarChart
                title={'Mióta dolgozik az adott munkahelyen?'}
                data={dataForYearChart}
            />

            <TszkIndex
                title={'TSZK Index'}
                data={dataForYearChart}
            />

            <TszkBarChart
                title={'Betöltött pozíció'}
                data={dataForPositionChart}
                customizedAxisTick={<CustomizedAxisTick />}
            />

            <TszkIndex
                title={'TSZK Index'}
                data={dataForPositionChart}
            />

            <TszkBarChart
                title={'A vezető és nem vezető beosztásban lévők vélekedésének összehasonlítása'}
                data={dataForLeaderChart}
                customizedAxisTick={<CustomizedAxisTick />}
            // dataKey={titles["db"]}
            />
            <TszkIndex
                height={120}
                yAxisWidth={50}
                title={'TSZK Index'}
                data={dataForLeaderChart}
            />

            <h3>A vezető és nem vezető beosztásban lévők vélekedésének összehasonlítása 2.</h3>
            <ResponsiveContainer width="80%" height={300}>
                <BarChart
                    width={800}
                    height={300}
                    data={dataForLeaderChart}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={titles["scale1_percent"]} fill={chartColors[0]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["scale2_percent"]} fill={chartColors[1]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["scale3_percent"]} fill={chartColors[2]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["scale4_percent"]} fill={chartColors[3]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

            <h3>A vezető és nem vezető beosztásban lévők vélekedésének összehasonlítása 3.</h3>
            <ResponsiveContainer width="80%" height={300}>
                <BarChart
                    width={800}
                    height={300}
                    data={dataForLeaderChart}

                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={titles["subscale1_percent"]} fill={chartColors[0]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale2_percent"]} fill={chartColors[1]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale3_percent"]} fill={chartColors[2]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale4_percent"]} fill={chartColors[3]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale5_percent"]} fill={chartColors[4]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale6_percent"]} fill={chartColors[5]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale7_percent"]} fill={chartColors[6]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale8_percent"]} fill={chartColors[7]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["subscale9_percent"]} fill={chartColors[8]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

            <h3>A vezető és nem vezető beosztásban lévők vélekedésének összehasonlítása 4.</h3>
            <ResponsiveContainer width="80%" height={300}>
                <BarChart
                    width={800}
                    height={300}
                    data={dataForLeaderColumns2Chart}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={titles["vezető"]} fill={chartColors[0]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["nem_vezető"]} fill={chartColors[7]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>

                </BarChart>
            </ResponsiveContainer>
            <h3>A vezető és nem vezető beosztásban lévők vélekedésének összehasonlítása 5.</h3>
            <ResponsiveContainer width="80%" height={300}>
                <BarChart
                    width={800}
                    height={300}
                    data={dataForLeaderColumns3Chart}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis tick={<CustomizedAxisTick2 />} interval={0} dataKey="name" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={titles["vezető"]} fill={chartColors[0]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>
                    <Bar dataKey={titles["nem_vezető"]} fill={chartColors[7]} >
                        <LabelList content={renderCustomizedLabel2} />
                    </Bar>

                </BarChart>
            </ResponsiveContainer>
            <CSVLink data={base}>CSV export</CSVLink>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilledStats);
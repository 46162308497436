import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { MenuItem, TextField } from "@material-ui/core";

class FilledStatsTable extends Component {
  state = {
    orderby: "item",
    asc: "desc",
  };
  constructor(props) {
    super(props);
    this.handleChangeOrderBy = this.handleChangeOrderBy.bind(this);
    this.handleChangeDesc = this.handleChangeDesc.bind(this);

    // this.componentWillReceiveProps(props);
  }

  componentDidUpdate(prevProps) {
    // Tipikus használat (ne felejtsd el a propokat összehasonlítani):
    if (this.props.data !== this.state.data) {
      this.setState({
        data: this.props.data,
        orderby: "item",
      });
    }
  }
  handleChangeOrderBy(event, newAlignment) {
    console.log(event.target);
    console.log(newAlignment);
    this.setState({
      orderby: event.target.value,
    });
  }

  handleChangeDesc(event, newAlignment) {
    console.log(event.target);
    console.log(newAlignment);
    this.setState({
      asc: event.target.value,
    });
  }

  sortByKey = (array, key) => {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };
  arraySortByValueAsc = (array, key) => {
    return array.sort(function (a, b) {
      var x = a[1];
      var y = b[1];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };
  arraySortByValueDesc = (array, key) => {
    return array.sort(function (a, b) {
      var x = b[1];
      var y = a[1];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  render() {
    const { data, show, questionnaire } = this.props;
    const t1 = [
      "igazgató",
      "igazgató-helyettes",
      "osztályfőnök",
      "munkaközösség-vezető",
      "nem oktató munkatárs",
      "nem vezető beosztású pedagógus",
      "egyéb, éspedig:",
    ];
    const t3 = ["vezető", "nem vezető"];
    const t4 = [
      "kevesebb, mint 1 éve",
      "1-3 éve",
      "4-6 éve",
      "7-9 éve",
      "10-12 éve",
      "13-15 éve",
      "16-18 éve",
      "18-21 éve",
      "22-24 éve",
      "több mint 25 éve",
    ];
    let columns = [];
    switch (data) {
      case "allData":
        columns = ["everybody"];
        break;
      case "groupByYear":
        columns = t4;
        break;
      case "groupByPosition":
        columns = t1;
        break;
      case "groupByLeader":
        columns = t3;
        break;
      default:
        break;
    }
    let questionList = [];
    questionnaire.forEach((sections) =>
      sections.children.forEach(
        (question) => (questionList["answer" + question.id] = question.name)
      )
    );
    console.log("questionnaire", questionnaire);
    console.log("questionList", questionList);

    let d = [];
    if (this.state.orderby === "item") {
      const a = { ...this.props["allData"]["sum"]["everybody"] };
      console.log("a", a);

      const b = Object.entries(a);
      console.log("b", b);
      const c = this.arraySortByValueAsc(
        b.filter((word) => word[0][0] === "a")
      );
      console.log("c", c);
      d = b.filter((word) => word[0][0] === "a");
    } else {
      const a = { ...this.props[data][show][this.state.orderby] };
      console.log("a", a);

      const b = Object.entries(a);
      console.log("b", b);
      const c = this.arraySortByValueAsc(
        b.filter((word) => word[0][0] === "a")
      );
      console.log("c", c);
      if (this.state.asc === "desc") {
        d = this.arraySortByValueDesc(b.filter((word) => word[0][0] === "a"));
      } else {
        d = this.arraySortByValueAsc(b.filter((word) => word[0][0] === "a"));
      }

      // console.log("d", d);
      // d = b.filter((word) => word[0][0] === "a");
    }
    return (
      <>
        Rendezés:
        <TextField
          required
          id={"distribution"}
          value={this.state["orderby"]}
          name={"distribution"}
          select
          className={this.props.classes.selectfilter}
          // label={"Laboratory"}
          onChange={this.handleChangeOrderBy}
          // InputProps={{ disableUnderline: true }}
          InputProps={{
            classes: {
              input: this.props.classes.statSelect,
            },
          }}

          //  helperText={"Select lab"}
        >
          <MenuItem key={1} value={"item"}>
            Állítás
          </MenuItem>
          {columns.map((i, index) => {
            return (
              <MenuItem key={index} value={i}>
                {i === "everybody" ? "Minden kitöltés" : i}
              </MenuItem>
            );
          })}
        </TextField>
        Irány:
        <TextField
          required
          id={"distribution2"}
          value={this.state["asc"]}
          name={"distribution2"}
          select
          className={this.props.classes.selectfilter}
          // label={"Laboratory"}
          onChange={this.handleChangeDesc}
          // InputProps={{ disableUnderline: true }}
          InputProps={{
            classes: {
              input: this.props.classes.statSelect,
            },
          }}

          //  helperText={"Select lab"}
        >
          <MenuItem key={1} value={"asc"}>
            Növekvő
          </MenuItem>
          <MenuItem key={2} value={"desc"}>
            Csökkenő
          </MenuItem>
        </TextField>
        <TableContainer component={Paper}>
          <Table
            className={this.props.classes.table}
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Állítás</TableCell>
                {columns.map((q2, index3) => (
                  <TableCell align="left">
                    {q2 === "everybody" ? "Minden kitöltés" : q2}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {d.map((q1, index2) => (
                <TableRow>
                  <TableCell>
                    {q1[0].substr(6, 2)}. {questionList[q1[0]]}
                  </TableCell>
                  {columns.map((q2, index3) => (
                    <TableCell>
                      {this.props &&
                        this.props[data] &&
                        this.props[data][show] &&
                        this.props[data][show][q2] &&
                        parseFloat(this.props[data][show][q2][q1[0]]).toFixed(
                          2
                        )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            {/* {this.props.questionnaire.forEach((q) =>
          q.children.forEach((x) => <div>x.name</div>)
        )} */}
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default FilledStatsTable;

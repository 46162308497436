export const ADD_TODO_START = 'ADD_TODO_START';
export const ADD_TODO_SUCESS = 'ADD_TODO_SUCESS';
export const ADD_TODO_FAIL = 'ADD_TODO_FAIL';

export const UPDATE_TODO_START = 'UPDATE_TODO_START';
export const UPDATE_TODO_SUCESS = 'UPDATE_TODO_SUCESS';
export const UPDATE_TODO_FAIL = 'UPDATE_TODO_FAIL';

export const DELETE_TODO_START = 'DELETE_TODO_START';
export const DELETE_TODO_SUCESS = 'DELETE_TODO_SUCESS';
export const DELETE_TODO_FAIL = 'DELETE_TODO_FAIL';

export const SIGN_IN_START = 'SIGN_IN_START';
export const SIGN_IN_SUCESS = 'SIGN_IN_SUCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_SUCESS = 'SIGN_UP_SUCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';

export const SIGN_OUT_START = 'SIGN_OUT_START';
export const SIGN_OUT_SUCESS = 'SIGN_OUT_SUCESS';
export const SIGN_OUT_FAIL = 'SIGN_OUT_FAIL';

export const SET_MENU = 'SET_MENU';
export const MEASURE_FORM = 'MEASURE_FORM';
export const SET_INSTITUTION = 'SET_INSTITUTION';
export const SET_CHART_DATA = 'SET_CHART_DATA';

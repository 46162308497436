import React, { Component } from 'react';

class CustomizedTick extends Component {
    render() {
        const { active, payload, titles } = this.props;
        return (
            <div>
                xxx
            </div>
        );
    }
}

export default CustomizedTick;
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import { useHistory } from 'react-router-dom';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import PermissionRow from './PermissionRow';
import { Delete, Add } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import { MenuItem, TextField } from '@material-ui/core';

class PermissionTable extends Component {
    constructor(props) {
        super();
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStop = this.handleStop.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            data: [],
            page: 0,
            rowsPerPage: 10,
            val: {
                user: null,
                questionnaire: null,
            },
            form: {
                date: new Date('2015-01-01'),
            }
        }
    }
    handleChange(e) {
        let n = this.state.val;
        n[e.target.name] = e.target.value;
        this.setState({
            val: { ...n }
        });
        // this.props.handleChangeForm(n);
    }
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    };
    convertArrayById = (aa) => {
        let a = [];
        aa && aa.forEach(p => {
            a[p.id] = p;
        })

        return a;
    }
    handleSubmit = (e) => {
        this.setState({ open: true });
    }
    handleStop = (e) => {
        this.setState({ open: false });
    }
    render() {
        const { classes, users, questionnaires, permissions } = this.props;
        // let a = [];
        //     users && users.forEach(p => {
        //         a[p.id]=p;
        //     })
        let usersArray = this.convertArrayById(users);
        let questionnairesArray = this.convertArrayById(questionnaires);
        let permissionsArray = this.convertArrayById(permissions);
        console.log('questionnairesArray', questionnairesArray);
        console.log('usersArray', usersArray);
        console.log('permissionsArray', permissionsArray);
        return (
            <div>
                <Box display="flex" p={1}>
                    <Box p={1} flexGrow={1}>
                        <h1>Vizsgálati személyek</h1>
                    </Box>
                    <Box p={1}>
                        <Button

                            type='submit'
                            // variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                                this.handleSubmit();

                            }}
                        >
                            <Add /> Új vizsgálati személy
                        </Button>
                    </Box>
                </Box>
                <TableContainer
                    // style={{ border: "1px solid rgba(0,0,0,0.2)", padding: 4 }}
                    className={classes.table}
                    component={Paper}>
                    <Table size="small" classes={{ root: classes.customTable }} aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ maxWidth: '200px', padding: '4px' }} component="th" scope="row">
                                    Felhasználó
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Admin
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Teszt
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Művelet
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                permissions && permissions.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((element, index) => (
                                        <PermissionRow
                                            key={index}
                                            index={index}
                                            element={element}
                                            users={usersArray}
                                            questionnaires={questionnairesArray}
                                        // classes={classes}
                                        // handleDelete={(e) => this.handleDelete(e)}
                                        // handleChangeResult={(e, result) => this.handleChangeResult(e, result)}
                                        // importElement={(element, counter) => this.importElement(element, counter)}
                                        />
                                    ))
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    // style={{ width: '500px', display: 'flex' }}
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={this.props.permissions.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={(e, a) => this.handleChangePage(e, a)}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                // ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Dialog
                    fullWidth={true}
                    classes={{ paper: classes.csvdialog }}
                    open={this.state.open}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Új vizsgálati személy</DialogTitle>
                    <DialogContent>
                        {usersArray && questionnairesArray &&
                            <form>
                                <div>
                                    < TextField
                                        required
                                        disabled={this.state.isExist}
                                        id={"user"}
                                        name={"user"}
                                        // defaultValue={selectedLab.devices[0].id}
                                        value={this.state.val['user']}
                                        select
                                        label={"user"}
                                        onChange={this.handleChange}
                                        helperText={"Felhasználó"}
                                    >
                                        {Object.values(usersArray).map((option, index) => (
                                            <MenuItem key={index} value={index}>
                                                {option.email}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div>
                                    < TextField
                                        required
                                        disabled={this.state.isExist}
                                        id={"questionnaire"}
                                        name={"questionnaire"}
                                        // defaultValue={selectedLab.devices[0].id}
                                        value={this.state.val['questionnaire']}
                                        select
                                        label={"questionnaire"}
                                        onChange={this.handleChange}
                                        helperText={"Kérdőív"}
                                    >
                                        {Object.values(questionnairesArray).map((option, index) => (
                                            <MenuItem key={index} value={index}>
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </form>
                        }
                    </DialogContent>
                    <DialogActions>

                        <Button
                            onClick={() => {
                                this.handleStop();
                            }}
                            color="primary"
                            autoFocus
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                this.handleImport();
                            }}
                            color="primary"
                            variant="contained"
                            autoFocus
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default PermissionTable;
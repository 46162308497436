import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { green, red, orange } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { updateEvaluations, updateAnswers, updateStatus } from '../../../store/actions/filledQuestionnaireActions';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        bgcolor: 'primary.main',
        marginBottom: theme.spacing(4),
    },
    question: {
        height: '40vh',
        textAlign: "center",
        alignContent: "center",
        backgroundColor: 'primary.main',
    },
    questionPanel: {
        textAlign: "center",
        alignContent: "center",
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    button: {
        margin: theme.spacing(1),
    },
    error: {
        color: '#eaa',
    },
    containedGreen: {
        color: '#fff',
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
            '@media (hover: none)': {
                backgroundColor: green[500],
            },
        },
    },
    containedOrange: {
        color: '#fff',
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[700],
            '@media (hover: none)': {
                backgroundColor: orange[500],
            },
        },
    },
    containedRed: {
        color: '#fff',
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
            '@media (hover: none)': {
                backgroundColor: red[500],
            },
        },
    },
    tooltipTitle: {
        margin: '-10px',
        color: '#666',
        top:'5px'
      },
      customTooltip: {
        margin: "0",
        lineHeight: "5px",
        border: "1px solid #f5f5f5",
        backgroundColor: "rgba(255, 255, 255, 255)",
        paddingBottom: "0%",
    }
}));

const FilledView = (props) => {
    const classes = useStyles();

    const evaluate = () => {
        let result = {};
        let params = [];
        if (questionnaire.scales.api) {
            result[1] = '';
            let values = '';
            Object.keys(filledQuestionnaire.answers).forEach(key => {
                values = values + filledQuestionnaire.answers[key].toString();
            });
            params['id'] = values;
            Object.keys(questionnaire.scales.parameters).forEach((key) => {
                params[questionnaire.scales.parameters[key]] = filledQuestionnaire.personal[key];
            });
            var url = new URL(questionnaire.scales.api);
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
            fetch(url)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log('result', result);
                        props.updateEvaluations(result, filledQuestionnaire.id);
                    },
                    (error) => {
                        console.log('ERROR', error);
                    }
                )
        } else {
            questionnaire.scales.forEach(scale => {
                let v = 0;
                let sub_result = {};
                scale.children.forEach(sub => {
                    let s = 0;
                    sub.values.forEach(value => {
                        s += filledQuestionnaire.answers[value];
                    });
                    v += s;
                    sub_result[sub.id] = {
                        value: s
                    };
                });
                let evaluation = {};
                scale.evaluation.forEach(e => {
                    if (e.min <= v && v <= e.max) {
                        evaluation = e;
                    }
                });
                result[scale.id] = {
                    value: v,
                    evaluation: evaluation,
                    sub_scales: sub_result
                };
            });
            props.updateEvaluations(result, filledQuestionnaire.id)
        }
    };

    const deleteAnswers = () => {
        props.updateAnswers({}, filledQuestionnaire.id);
        props.updateEvaluations({}, filledQuestionnaire.id);
        props.updateStatus('start', filledQuestionnaire.id)
    }

    useFirestoreConnect([
        {
            collection: 'questionnaires',
            doc: props.filledquestionnaires ? props.filledquestionnaires[0].docId : null
        },
        {
            collection: 'filledquestionnaires',
            doc: props.match.params.id
        },
        {
            collection: 'users',
            doc: props.filledquestionnaires ? props.filledquestionnaires[0].authorId : null
        },
    ]);

    const filledQuestionnaire = props.filledquestionnaires ? props.filledquestionnaires[0] : null;
    console.log('------------------'.props);
    console.log(filledQuestionnaire);

    const questionnaire = props.questionnaires && filledQuestionnaire ? props.questionnaires.filter(x => x.id === filledQuestionnaire.docId)[0] : {};
    console.log(questionnaire);
    const user = props.users ? props.users[0] : {};
    const allQuestionAnswered = filledQuestionnaire && filledQuestionnaire.answers ? Object.keys(filledQuestionnaire.answers).length : 0;
    const evaluated = filledQuestionnaire ? filledQuestionnaire.status === 'evaluated' : null;
    let questionNumber = 0;
    let questionList = [];

    if (questionnaire.questionnaire) {
        questionnaire.questionnaire.forEach(sections => (
            sections.children.forEach(question => (
                questionList[question.id] = question.name
            ))
        ));
        questionNumber = Object.keys(questionList).pop();
    }
    let personal = [];
    if (questionnaire.personal) {
        questionnaire.personal.forEach(p => (
            personal[p.id] = p
        ));
    }
    console.log(props);
    let data = [];
    var tooltip
    const CustomTooltip = ({ active, payload, label }) => {
        console.log(payload);
        if (active && payload && payload.length) {
          return (
            <div className="customTooltip">
              <p className="tooltip_title">{`${payload[0].payload.validity_title} : ${payload[0].value} ${payload[0].payload.validity}`}</p>
              <p className="desc">{`${label} : ${payload[0].payload.validity_text}`}</p>
            </div>
          );
        }
      
        return null;
      };
    const barColors = { verylow: "#e74c3c", low: "#f39c12", normal: "#01FF70", high: "#f39c12", veryhigh: "#e74c3c" };
    console.log('---------------', filledQuestionnaire);
    if (filledQuestionnaire && filledQuestionnaire.evaluations && filledQuestionnaire.evaluations.base_scales) {
        Object.keys(filledQuestionnaire.evaluations.base_scales).forEach(q => {
            console.log('---------------', filledQuestionnaire.evaluations.base_scales[q]);
            data.push({
                name: filledQuestionnaire.evaluations.base_scales[q].name,
                value: filledQuestionnaire.evaluations.base_scales[q].value,
                variance: filledQuestionnaire.evaluations.base_scales[q].variance,
                standard: filledQuestionnaire.evaluations.base_scales[q].standard,
                validity: filledQuestionnaire.evaluations.base_scales[q].validity,
                validity_title: filledQuestionnaire.evaluations.base_scales[q].validity_title,
                validity_text: filledQuestionnaire.evaluations.base_scales[q].validity_text,
            });
        }
        );
    };
    console.log('sssssssssssssssssssss', data);
    return (
        <Container maxWidth='sm'>
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                mt='1.5rem'
            >
                <div className={classes.root}>
                    <ExpansionPanel expanded={true} className={classes.questionPanel} >
                        <ExpansionPanelSummary className={evaluated ? classes.containedGreen : allQuestionAnswered == questionNumber ? classes.containedOrange : classes.containedRed} >
                            {questionnaire && questionnaire.title}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails >
                            Felhasználó: {user && user.email}
                        </ExpansionPanelDetails>
                        <ExpansionPanelDetails >
                            Doc: {filledQuestionnaire && filledQuestionnaire.docId}
                        </ExpansionPanelDetails>
                        <Divider />
                        <ExpansionPanelActions>
                            <Button
                                variant='contained'
                                className={classes.button}
                                disabled={allQuestionAnswered ? false : true}
                                onClick={(e) => { deleteAnswers() }}
                                color={allQuestionAnswered ? 'secondary' : 'contained'}
                            >
                                Eredmények törlése
                            </Button>
                            <Button
                                variant='contained'
                                className={classes.button}
                                disabled={allQuestionAnswered ? false : true}
                                onClick={(e) => { evaluate() }}
                                color={allQuestionAnswered ? 'primary' : 'contained'}
                            >
                                Kiértékel
                            </Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                    <h2>Adatok</h2>
                    {filledQuestionnaire && filledQuestionnaire.personal && personal.length > 0 &&
                        Object.keys(filledQuestionnaire.personal).map((key) => {
                            return <div>
                                {personal[key].label}:
                                {(personal[key].type == 2 || personal[key].type == 4) &&
                                    filledQuestionnaire.personal[key]
                                }
                                {personal[key].type == 3 &&
                                    personal[key].values.filter(x => x.value == filledQuestionnaire.personal[key])[0].label
                                }
                            </div>
                        })
                    }

                    <h2>Kiértékelés</h2>
                    <h3>Validitás</h3>
                    {filledQuestionnaire && filledQuestionnaire.evaluations && filledQuestionnaire.evaluations.validity_scales && filledQuestionnaire.evaluations.validity_scales.subscale &&
                        Object.keys(filledQuestionnaire.evaluations.validity_scales.subscale).map((key) => {
                            return <div>
                                <div className={filledQuestionnaire.evaluations.validity_scales.subscale[key].evaluation === 'normal' ? classes.containedGreen : classes.containedRed}>
                                    {filledQuestionnaire.evaluations.base_scales[key].validity_title}: {filledQuestionnaire.evaluations.validity_scales.subscale[key].evaluation}
                                </div>
        
                            </div>
                        })
                    }
                    <h3>Skálák</h3>
                    {filledQuestionnaire && filledQuestionnaire.evaluations && Array.isArray(questionnaire.scales) &&
                        Object.keys(filledQuestionnaire.evaluations).map((key) => {
                            return <div>
                                <div className={classes.containedGreen}>
                                    {questionnaire.scales.filter(x => x.id == key)[0].name}: {filledQuestionnaire.evaluations[key].evaluation.name} ({filledQuestionnaire.evaluations[key].value})
                                </div>
                                {
                                    Object.keys(filledQuestionnaire.evaluations[key].sub_scales).map((key2) => {
                                        return <div>
                                            {questionnaire.scales.filter(x => x.id == key)[0].children.filter(x => x.id == key2)[0].name}: {key2}
                                        </div>
                                    })
                                }
                            </div>
                        })
                    }
                    {data &&
                        <BarChart
                            width={800}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Bar dataKey="value" fill="#82ca9d">
                                {
                                    data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={barColors[entry.validity]} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    }
                    {filledQuestionnaire && filledQuestionnaire.evaluations && filledQuestionnaire.evaluations.scales && !Array.isArray(questionnaire.scales) &&
                        Object.keys(filledQuestionnaire.evaluations.base_scales).map((key) => {
                            console.log(filledQuestionnaire.evaluations.base_scales[key].name)
                            return <div>
                                <h3>
                                {filledQuestionnaire.evaluations.base_scales[key].validity_title}
                                </h3>
                                {key}: {filledQuestionnaire.evaluations.base_scales[key].value}
                                <div>
                                {filledQuestionnaire.evaluations.base_scales[key].validity_text}
                                </div>
                            </div>
                        })

                    }
                    <h2>Válaszok</h2>
                    {filledQuestionnaire && filledQuestionnaire.answers &&
                        Object.keys(filledQuestionnaire.answers).map((key) => {
                            return <div className={filledQuestionnaire.answers[key] ? classes.containedGreen : classes.containedRed}>
                                {key} .{questionList[key]}
                            </div>
                        })
                    }
                    {questionnaire && questionnaire.id}-
                    {filledQuestionnaire && filledQuestionnaire.id}-
                    {user && user.email}-
                </div>
            </Box>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        questionnaires: state.firestore.ordered.questionnaires,
        filledquestionnaires: state.firestore.ordered.filledquestionnaires,
        users: state.firestore.ordered.users,
        authStatus: state.firebase.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateEvaluations: (evaluations, id) => dispatch(updateEvaluations(evaluations, id)),
        updateAnswers: (evaluations, id) => dispatch(updateAnswers(evaluations, id)),
        updateStatus: (evaluations, id) => dispatch(updateStatus(evaluations, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilledView);
import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { addInstitution, deleteInstitution, updateInstitution } from '../../../store/actions/institutionActions';
import Container from '@material-ui/core/Container';
import useStyles from "../dashboard/styles";
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import { firestore } from 'firebase';
import { MenuItem, TextField } from '@material-ui/core';
import { setInstitution } from '../../../store/actions/menuActions';

const InstitutionSelect = ({ actualInstitution, admins, institutions, authStatus, addInstitution, updateInstitution, deleteInstitution }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isEmpty, isLoaded } = authStatus;
    const form = {
        titles: {
            index: 'Intézmények',
            create: 'Új intézmény',
            update: 'Intézmény módosítása',
        },
        keyField: 0,
        indexFields: [0, 1],
        fields: [
            {
                id: 0,
                name: 'om',
                required: true,
                helper: 'OM',
                q2: 'OM',
                label: 'OM',
                type: 2,
            },
            {
                id: 1,
                required: true,
                name: 'name',
                helper: 'Intézmény neve',
                q2: 'Intézmény neve',
                label: 'Intézmény neve',
                type: 2,
            }
        ]
    };
    useFirestoreConnect([
        {
            collection: "institutions",
            storeAs: 'institutions',
            // doc: ['1tKAePwhQW99YAAXarTy'],
            // where: [
            //     // ['uid', 'in', admins && admins[0] && admins[0].institution  ? admins[0].institution : ['1tKAePwhQW99YAAXarTy']],
            //     [firestore.FieldPath.documentId(), 'in', admins && admins[0] && admins[0].institution ? admins[0].institution.slice(0,9) : ['xx']],
            // ],
        },
        {
            collection: "admins",
            doc: authStatus.uid,
            //    where: [
            //     ['doc', '==', authStatus.uid],
            // ],
            storeAs: 'admins'
        }
    ]);

    const handleSelectInsitution = (event) => {
        dispatch({ type: actions.SET_INSTITUTION, payload: event.target.value })
    };

    if (
        ((actualInstitution == '' && actualInstitution != 'Összes intézmény') &&
            admins && admins[0] &&
            admins[0].institution &&
            admins[0].institution.length > 0
        ) ||
        ((actualInstitution == '' && actualInstitution != 'Összes intézmény') &&
            admins && admins[0] &&
            admins[0].institution &&
            admins[0].institution.length > 0 &&
            admins[0].institution.indexOf(actualInstitution) < 0
        )) {
        dispatch({ type: actions.SET_INSTITUTION, payload: admins[0].institution[0] })
    }
    return (
        <TextField
            id="outlined-select-currency"
            select
            // label="Select"
            value={actualInstitution}
            onChange={handleSelectInsitution}
            // helperText="Intézmény"
            InputProps={{
                classes: {
                    input: classes.institutionSelect
                }
            }}
        >
            <MenuItem key={"Összes intézmény"} value={"Összes intézmény"}>
                {"Összes intézmény"}
            </MenuItem>
            {institutions && institutions.map((option) => (
                admins && admins[0] && admins[0].institution && admins[0].institution.indexOf(option.id)>-1 &&
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>


            ))}
        </TextField>
    );
};

const mapStateToProps = (state) => {
    return {
        institutions: state.firestore.ordered.institutions,
        admins: state.firestore.ordered.admins,
        authStatus: state.firebase.auth,
        actualInstitution: state.questionnaire.institution,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInstitution: (institution) => dispatch(setInstitution(institution)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionSelect);

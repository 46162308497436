import React, { Component } from 'react';
import { TooltipPayload } from 'recharts';
import { Card, CardContent, Typography } from '@material-ui/core';

class StatisticsBarChartTooltip extends Component {
  render() {
    const { active, payload, titles } = this.props;
    console.log('payload', payload);
    return (
      <>
        {active && payload && (
          <Card>
            <CardContent>
              <Typography>{`${titles[payload[0].payload.name]}`}</Typography>
              {payload.map((element, index) => (
                <Typography>{`${titles[element.name]}: ${element.value}`}</Typography>
              ))}
            </CardContent>
          </Card>
        )}
      </>
    );
  }
}

export default StatisticsBarChartTooltip;
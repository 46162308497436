import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CreateIcon from '@material-ui/icons/Create';
import { addQuestionnaire } from '../../../store/actions/questionnaireActions';


class QuestionnaireItem extends Component {
  constructor(props) {
    super(props);
    this.addQuestion2 = this.addQuestion2.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.state = {
      email: 'xcc'
  };
  }

  addQuestion2 = (e) => {
    this.props.question.children.push(this.state.email);
  };
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
}

  render() {
    return (
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1c-content'
          id='panel1c-header'
        >
          <h3 className="theList" >
            {this.props.question.question}
          </h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Kérdések</Typography>
        </ExpansionPanelDetails>
        {this.props.question.children.map((h, index) => {
          return (
            <div>
              {h.name}
            </div>

          );
        })}
        <Divider />
        <ExpansionPanelActions>
          <input
            // className={classes.margin}
            placeholder="kérdéscsoport"
            onChange={this.handleEmailChange}
            // onChange={this.addQuestion2(this.props.question)}
          >
          </input>
          <Button
            variant='contained'
            color='primary'
            startIcon={<CreateIcon />}
            onClick={this.addQuestion2}
          >
            Új kérdés
        </Button>
          <Button
            variant='contained'
            color='secondary'
            // className={classes.containedRed}
            startIcon={<DeleteIcon />}
            onClick={() => {
              this.props.deleteItem(this.props.index);
            }}
          >
            Törlés
            </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  }
}

export default QuestionnaireItem;
import React, { Component } from 'react';
import QuestionnaireItem from './QuestionnaireItem';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { FormBuilder } from '@ginkgo-bioworks/react-json-schema-form-builder';
import Form2 from '@rjsf/core';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginTop: theme.spacing(2),
    },
    marginButton: {
        marginTop: theme.spacing(4),
    },
    rounded: {
        color: '#fff',
        backgroundColor: green[500],
        marginTop: theme.spacing(4),
    },
    select: {
        marginTop: theme.spacing(2),
        fontSize: '16px',
    },
    circularProgressWhite: {
        color: '#fff',
    },
}));


class QuestionnaireItems extends Component {
    constructor(props) {
        let arr = [];
        console.log(props);
        console.log(Object.keys(props.items));
        Object.keys(props.items).forEach(key => arr.push(props.items[key]))
        // console.log(Object.keys(this.props.items));
        super(props);
        this.state = {
            items: arr,
            email: 'xcc',
            schema: '{}',
            uischema: '{}',
            formData: {},
            json1: props.items
        };
        this.addItem = this.addItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
    }
    addItem = (e) => {
        e.preventDefault();
        console.log(this.state.email);
        let a = this.state.email;
        this.setState((prevState) => {
            return {
                items: prevState.items.concat({ type: 1, question: this.state.email, children: [] })
            };
        });
        this.props.update([...this.state.items, { type: 1, question: this.state.email, children: [] }])
        // if (this._inputElement.value !== "") {
        //     var newItem = {
        //         text: this._inputElement.value,
        //         key: Date.now()
        //     };

        //     this.setState((prevState) => {
        //         return {
        //             items: prevState.items.concat(newItem)
        //         };
        //     });

        //     this._inputElement.value = "";
        // }

        console.log(this.state.items);



    };
    onChangeJson = (e) => {
        console.log(e);
        this.setState({ json1: e.jsObject });
        
    }
    jsonSave = () =>{
        this.props.update(this.state.json1);
    }
    deleteItem(key) {
        console.log('KEY', key);
        console.log('ITEMS', this.state.items);
        var filteredItems = this.state.items.filter(function (item, index) {
            return (index !== key);
        });

        this.setState({
            items: filteredItems
        });
        this.props.update(filteredItems)
    }
    handleEmailChange(e) {
        this.setState({ email: e.target.value });
    }

    render() {
        return (
            <div>
                <h2>Kérdések</h2>
                <JSONInput
                    id='a_unique_id'
                    placeholder={this.props.items}
                    // colors={darktheme}
                    locale={locale}
                    onChange={this.onChangeJson}
                    height='550px'
                />
                 <Button
                        variant='contained'
                        color='primary'
                        startIcon={<CreateIcon />}
                        onClick={this.jsonSave}
                    >
                        Json ment
                    </Button>
                <form>
                    <input
                        // className={classes.margin}
                        placeholder="kérdéscsoport"
                        onChange={this.handleEmailChange}>
                    </input>
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<CreateIcon />}
                        onClick={this.addItem}
                    >
                        Új kérdéscsoport
                    </Button>
                </form>
                <div>
                    {this.props.items.map((h, index) => {
                        return (
                            <QuestionnaireItem
                                key={index}
                                index={index}
                                question={h}
                                deleteItem={this.deleteItem}
                            />

                        );
                    })}
                </div>


            </div>
        );
    }
}

export default QuestionnaireItems;
import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { addSampling, deleteSampling, updateSampling } from '../../../store/actions/samplingActions';
import Container from '@material-ui/core/Container';
import useStyles from "../dashboard/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import FSPTable from '../../common/FSPTable/FSPTable';


const SamplingComponent = ({ sampling, questionnaires, authStatus, addSampling, updateSampling, deleteSampling }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isEmpty, isLoaded } = authStatus;
    const convertArrayById = (model, field = 'id') => {
        let converted = [];
        model && model.forEach(record => {
            converted[record[field]] = record;
        })

        return converted;
    }

    let questionnairesArray = questionnaires ? convertArrayById(questionnaires) : [];
    console.log('questionnairesArray', questionnairesArray);
    console.log('questionnaires', questionnaires);
    const form = {
        titles: {
            index: 'Mintavételek',
            create: 'Új mintavétel',
            update: 'Mintavétel módosítása',
        },
        keyField: 0,
        indexFields: [0, 1, 2],
        fields: [
            {
                id: 0,
                name: 'om',
                required: true,
                helper: 'Mintavétel kód',
                q2: 'Mintavétel kód',
                label: 'Mintavétel kód',
                type: 2,
            },
            {
                id: 1,
                required: true,
                name: 'name',
                helper: 'Mintavétel megnevezése',
                q2: 'Mintavétel megnevezése',
                label: 'Mintavétel megnevezése',
                type: 3,
                values: questionnaires,
                valuesArray: questionnairesArray,
                values_label: 'title',
                values_key: 'id',
            },
            {
                id: 2,
                required: true,
                name: 'status',
                helper: 'Aktív',
                q2: 'Aktív',
                label: 'Aktív',
                type: 3,
                values_label: 'label',
                values_key: 'value',
                values: [
                    {
                        "label": "Nem",
                        "value": "0"
                    },
                    {
                        "label": "Igen",
                        "value": "1"
                    },
                ],
                valuesArray: convertArrayById([
                    {
                        "label": "Nem",
                        "value": "0"
                    },
                    {
                        "label": "Igen",
                        "value": "1"
                    },
                ], 'value')
            }
        ]
    };
    useFirestoreConnect([
        {
            collection: "sampling",
            storeAs: 'sampling'
        },
        {
            collection: 'questionnaires',
            orderBy: ['createdAt', 'asc']
        },
    ]);

    const createElement = (newMeasure) => {
        if (typeof newMeasure.id === 'undefined') {
            addSampling(newMeasure);
        } else {
            updateSampling(newMeasure, newMeasure.id)
        }
    }

    const deleteElement = (newMeasure) => {
        deleteSampling(newMeasure);
    }

    dispatch({ type: actions.SET_MENU, payload: 'Mintavételek' })

    return (
        <Container className={classes.content}>
            <div>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        sampling ?
                            <FSPTable
                                model={sampling}
                                classes={classes}
                                createElement={(measure) => createElement(measure)}
                                deleteElement={(measure) => deleteElement(measure)}
                                form={form}
                            /> :
                            <CircularProgress />
                    ))}
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        sampling: state.firestore.ordered.sampling,
        authStatus: state.firebase.auth,
        questionnaires: state.firestore.ordered.questionnaires,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addSampling: (institution) => dispatch(addSampling(institution)),
        deleteSampling: (id) => dispatch(deleteSampling(id)),
        updateSampling: (institution, id) => dispatch(updateSampling(institution, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SamplingComponent);

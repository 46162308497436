import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { green, red, orange } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Filled from './Filled';
import { updateEvaluations } from '../../../store/actions/filledQuestionnaireActions';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        bgcolor: 'primary.main',
        marginBottom: theme.spacing(4),
    },
    question: {
        height: '40vh',
        textAlign: "center",
        alignContent: "center",
        backgroundColor: 'primary.main',
    },
    questionPanel: {
        textAlign: "center",
        alignContent: "center",
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    button: {
        margin: theme.spacing(1),
    },
    error: {
        color: '#eaa',
    },
    containedGreen: {
        color: '#fff',
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
            '@media (hover: none)': {
                backgroundColor: green[500],
            },
        },
    },
    containedOrange: {
        color: '#fff',
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[700],
            '@media (hover: none)': {
                backgroundColor: orange[500],
            },
        },
    },
    containedRed: {
        color: '#fff',
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
            '@media (hover: none)': {
                backgroundColor: red[500],
            },
        },
    },
}));

const Evaluation = (props) => {
    const classes = useStyles();
    const { authStatus, filledquestionnaires, questionnaires, users, updateEvaluations } = props;
    const { isEmpty, isLoaded } = authStatus;
    useFirestoreConnect([
        {
            collection: 'questionnaires',
            doc: props.match.params.id
        },
        {
            collection: 'filledquestionnaires',
            where: [
                ['docId', '==', props.match.params.id],
            ],
        },
        {
            collection: 'users',
        },
    ]);
    let u = [];
    if (users) {
        users.forEach(x => (
            u[x.id] = x
        ))
    }

    let questionlist = [];
    let max = 0;
    if (questionnaires) {
        questionlist = [];
        questionnaires[0].questionnaire.forEach(q => (
            q.children.forEach(x => (
                questionlist[x.id] = x.name
            ))
        ));
        max = Object.keys(questionlist).pop();
    }

    return (
        <Container maxWidth='sm'>
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                mt='1.5rem'
            >
                <h2>{questionnaires &&
                    questionnaires[0].title}
                </h2>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        filledquestionnaires &&
                        filledquestionnaires.map((fill) => {
                            return <Filled
                                questionnaire={questionnaires[0]}
                                updateEvaluations={props.updateEvaluations}
                                users={u}
                                questions={questionlist}
                                n={max}
                                filled={fill}
                                key={fill.id}
                                classes={classes}
                            />;
                        })
                    )
                    )
                }
            </Box>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        questionnaires: state.firestore.ordered.questionnaires,
        filledquestionnaires: state.firestore.ordered.filledquestionnaires,
        users: state.firestore.ordered.users,
        authStatus: state.firebase.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateEvaluations: (evaluations, id) => dispatch(updateEvaluations(evaluations, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Evaluation);